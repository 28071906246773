import React, { Component } from 'react';

import { FlatList } from 'react-native';

import { List, ListItem, ListSection, Avatar } from 'material-bread';

// Re-implement using FlatList

export default class AppList extends Component<any, any> {
	static defaultProps = {
		options: {
			type: 'normal', // expand
		},
		data: [] // type, title, value, left, right, options: { disabled: false }
	};

	public show(title: string, data: any[]) {
		this.setState({
			isVisible: true,
			title
		});

		// Safe hide
		setTimeout(this.hide.bind(this), 10000);
	}

	public hide() {
		this.setState({
			isVisible: false
		});
	}

	renderItem({ item, index }) {
		let defaultStyle = { backgroundColor: 'white' };

		if (item?.type === 'title') {
			return (
				<ListSection label={item.title} labelStyle={{ paddingLeft: 15, paddingTop: 15, paddingBottom: 0, marginBottom: 0, ...defaultStyle, ...(item.style ?? {}) }} contentStyle={{ padding: 0, margin: 0 }} />
			);
		}

		if (!item) return null;

		let rowProps: any = {
			style: { ...defaultStyle }
		};

		if (item?.id ?? false) {
			rowProps.key = `list:row:${item.id}`;
		}

		if (!!item.title) {
			rowProps.text = item.title;
		} else {
			rowProps.textStyle = { height: 0 };
		}

		if (!!item.value) {
			rowProps.secondaryText = item.value;
		}

		if (!!item.icon) {
			rowProps.media = <Avatar
				type="icon"
				content={item.icon}
				contentColor={'black'}
				color={'transparent'}
				size={40}
			/>;
		}

		if (!!item.left) {
			rowProps.leadingActionItem = item.left;
		}

		if (!!item.right) {
			rowProps.actionItem = item.right;
		}

		if (!!item.onPress) {
			rowProps.onPress = item.onPress;
		}

		if(!!item.style) {
			rowProps.style = { ...rowProps.style, ...item.style };
		}

		if(item.hasFocus === false) {
			rowProps.rippleProps = { disabled: true };
		}

		return <ListItem {...rowProps} />;
	}

	public render() {
		let { title, data, options, style } = this.props;

		if (!!title && title !== '') {
			data = [{
				type: 'title',
				title
			}, ...data];
		}

		// title, value, left, right, options
		// null must be ignored :D
		return (
			<FlatList
				style={{ width: '100%', backgroundColor: 'white', ...(style ?? {}) }}
				keyboardShouldPersistTaps={'always'}
				showsVerticalScrollIndicator={false}
				data={data}
				renderItem={this.renderItem.bind(this)}
			/>
		);
	}
}
