import React, { Component } from 'react';
import { StyleSheet, View, FlatList, ScrollView, Dimensions } from 'react-native';

import Screen from './components/Screen';

import Modal from "./components/Modal";

import { Button, Searchbar, Text, Colors } from 'react-native-paper';

import AppBar from './components/AppBar';
import Title from './components/Title';

import Fuse from 'fuse.js';

import Requests from './helpers/Requests';
import Card from './components/Card';
import Loading from './components/Loading';

export default class OrdersScreen extends Component<any, any> {
	private data: any[];
	private fuse: any;
	private searchDefer: any = null;

	state: any = {
		clients: [],
		order: false,
		isOrderVisible: false,
		isLoadingVisible: true,

		searchText: ''
	};

	loading: Loading;

	async componentDidMount() {
		this.loading.show();
		let clients = await Requests.get('/clients?all=1', {});

		console.info('Clients', clients);

		// Copy into data for seach
		this.data = [...clients];

		this.fuse = new Fuse(this.data, {
			shouldSort: true,
			tokenize: true,
			threshold: 0.4,
			location: 0,
			distance: 100,
			maxPatternLength: 32,
			minMatchCharLength: 1,
			keys: ['CUIT', 'NOMBRE'],
		});

		this.setState({ clients });
		this.loading.hide();
	}

	private goBack() {
		this.props.navigation.goBack();
	}

	private async getClient(client) {
		console.info(client);
		this.setState({
			isOrderVisible: true,
			client
		});
	}

	private async runSearch() {
		let { searchText } = this.state,
			results = this.fuse.search(searchText);

		await this.setState({ data: results.map(r => r.item) });
	}

	private async clearSearch() {
		await this.setState({ clients: this.data });
	}

	renderItem({ item }) {
		return (
			<>
				<Card
					key={item.ID}
					title={item.NOMBRE}
					subtitle={`CUIT: ${item.CUIT}`}
					content={(
						<>
							<Text>Crédito: $ {item.CREDITO}</Text>
							<Text>Descuento: {item.DESCUENTO}%</Text>
							<Text>Inhabilitado: {item.INHABILITADO == 'N' ? 'No' : 'Si'}</Text>
						</>
					)}
					left={null}
					actions={[{
						title: 'Ver Cliente',
						onPress: async () => {
							console.info('Details: ', item);

							await this.getClient(item);
						}
					}]}
				/>
			</>
		);
	}

	private renderItems() {
		let { clients } = this.state;

		return (
			<>
				<View>

				</View>

				<FlatList
					keyboardShouldPersistTaps={'always'}
					showsVerticalScrollIndicator={false}
					data={clients}
					renderItem={this.renderItem.bind(this)}
					keyExtractor={(item: any, i) => item.id}
					style={{ width: '100%' }}
				/>
			</>
		);
	}

	renderDetailsModal() {
		let { client } = this.state;

		if (!client) return null;

		/*
		CA_1096_EMAIL_FACTURAS: null
COD_TRANSP: "BAT003"
COND_VTA: 2
CUIT: "20-26686940-2"
EAN: null
EMAIL_FAC: null
HABILITA_: null
ID_CATEGORIA_IVA: 1
ID_GVA14: 2133
PORC_IIBB: 0
TIPO_DOC: 80
credito: 0
descuento: 0
email: "info@albanesimayorista.com.ar"
id: "ALB006"
id_vendedor: "14"
inhabilitado: "N"
lista_precio: 13
nombre: "ALBANESI BERNARDO"
password: null
saldo: 0
saldo_elcor: 0
		*/

		let { NOMBRE, CUIT, EMAIL, CREDITO, DESCUENTO, INHABILITADO, SALDO_ELCOR, NOMBRE_LISTA_PRECIO, NOMBRE_TRANSPORTE, addresses } = client;

		return (
			<Modal
				isVisible={this.state.isOrderVisible}
				onBackButtonPress={() => this.setState({ isOrderVisible: false })}
				onBackdropPress={() => this.setState({ isOrderVisible: false })}
				style={{ margin: 10, padding: 0 }}>
				<View style={{ flexDirection: 'column', backgroundColor: 'white', borderRadius: 10 }}>
					<View
						style={{ width: '100%', padding: 15, borderBottomColor: '#EAEAEA', borderBottomWidth: 1 }}>
						<Title>{NOMBRE}</Title>
						<Text>CUIT: {CUIT}</Text>
						<Text>Email: {EMAIL}</Text>
						<Text style={{ color: INHABILITADO == 'N' ? Colors.green500 : Colors.red500, fontWeight: 'bold', marginBottom: 12 }}>Inhabilitado: {INHABILITADO == 'N' ? 'No' : 'Si'}</Text>
						<Text>Lista de Precio: {NOMBRE_LISTA_PRECIO}</Text>
						<Text>Transporte {NOMBRE_TRANSPORTE}</Text>
						<Text>Descuento: {DESCUENTO}%</Text>
						<Text>Crédito: $ {CREDITO}</Text>
						<Text style={{ fontWeight: 'bold' }}>Saldo: $ {SALDO_ELCOR}</Text>
					</View>

					{/* Show addresses from client */}
					<ScrollView
						style={{ width: '100%', maxHeight: Dimensions.get("window").height / 1.5 }}>
						{addresses.map((r) => {
							/*
							"COD_CLIENTE": "ZAP004",
							"COD_DIRECCION_ENTREGA": "PRINCIPAL",
							"COD_PROVINCIA": "12",
							"DIRECCION": "Hernandarias 2152",
							"HORARIO_ENTREGA": "",
							"ID_DIRECCION_ENTREGA": 825,
							"LOCALIDAD": "Santo Tomé",
							*/
							return (
								<Card
									key={`addr_${r.ID_DIRECCION_ENTREGA}`}
									title={r.COD_DIRECCION_ENTREGA}
									content={
										<>
											<View style={{ flexDirection: 'column' }}>
												<Text style={{ flex: 1 }}>Dirección {r.DIRECCION}</Text>
												<Text style={{ flex: 1 }}>Localidad {r.LOCALIDAD}</Text>
											</View>
										</>
									}
								/>
							)
						})}
					</ScrollView>

					<View
						style={{ width: '100%', marginTop: 0 }}>
						<Button
							style={{ padding: 15 }}
							onPress={() => this.setState({ isOrderVisible: false })}>Cerrar</Button>
					</View>
				</View>
			</Modal>
		);
	}

	render() {
		return (
			<Screen appBar={{
				hasBack: true,
				onBackPress: this.goBack.bind(this),
				title: "Listado de Clientes"
			}}>

				<Searchbar
					placeholder="Buscar..."
					onChangeText={async (query) => {
						await this.setState({ searchText: query });

						if (query.length === 0) {
							await this.clearSearch();
							clearTimeout(this.searchDefer);
							return;
						}

						// Run Search

						if (this.searchDefer) {
							clearTimeout(this.searchDefer);
						}

						this.searchDefer = setTimeout(async () => {
							await this.runSearch();
						}, 100);
					}}
					value={this.state.searchText}
				/>

				<View style={styles.container}>
					{this.renderItems()}
				</View>

				{this.renderDetailsModal()}

				<Loading ref={(ref) => this.loading = ref} />
			</Screen>
		);
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: '#fff',
		alignItems: 'center',
		padding: 0,
	},
});
