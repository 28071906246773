import SyncClient from './SyncClient';

class Home extends SyncClient {
	public url: string = `/user/home`;
	public requestType: string = 'GET';
	public storageKey: string = 'home';
	public maxDuration: number = (10 * (60 * 1000));
	public syncInterval: number = 0.5 * (60 * 1000);
}

export default new Home('Home');