import React, { Component } from 'react';

import { View, Text, Linking } from 'react-native';

import { Button, Card } from 'react-native-paper';

import Title from './Title';

import { theme } from '../../App';
import { Platform } from '@unimodules/core';

export default class AppCard extends Component<any, any> {
	private getButtonColor(type) {
		if (type && type == 'secondary') {
			return theme.colors.error;
		}

		if (type && type == 'normal') {
			return '#000000';
		}

		return theme.colors.primary;
	}
	public render() {
		let { props } = this,
			{ title, subtitle, left, right, content, image, actions, titleStyle } = props;

		let coverContent = null,
			coverPosition = 'top';
		if (image) {
			coverContent = <Card.Cover source={{ uri: image.url }} />;

			if (image.position) {
				coverPosition = image.position;
			}
		}

		return (
			<Card>
				{coverPosition === 'top' ? coverContent : null}
				<View style={{
					marginTop: 6,
					marginBottom: 8,
					flexDirection: 'row',
					flex: 1,
					width: '100%',
					display: 'flex',
					paddingLeft: 15,
					paddingRight: 15
				}}>
					<View style={{ display: 'flex', alignContent: 'flex-start' }}>
						{left}
					</View>
					<View style={{ flex: 1 }}>
						<Title style={titleStyle || {}}>{title}</Title>
						{subtitle && <Text>{subtitle}</Text>}
					</View>
					<View style={{ display: 'flex', alignContent: 'flex-end' }}>
						{right}
					</View>
				</View>
				{coverPosition === 'middle' ? coverContent : null}
				{content ? (
					<Card.Content>
						{content}
					</Card.Content>
				) : null}
				{coverPosition === 'bottom' ? coverContent : null}
				{actions && actions.length > 0 ? (
					<Card.Actions>
						{actions.map(action => {
							if (!action) return null;

							let btProps: any = {};
							if (action.onPress) btProps.onPress = action.onPress;
							if (action.props) btProps = { ...btProps, ...action.props };

							if (!!action.url && action.url !== '') {
								let webId = `btn_link_${action.key}`;

								return (
									<>
										{(Platform.OS === 'web') && (<a href={action.url} id={webId} target="_blank" />)}

										<Button color={this.getButtonColor(action.type)} {...btProps} onPress={() => {
											if (Platform.OS === 'web') {
												document.getElementById(`btn_link_${action.key}`).click();
											} else {
												Linking.openURL(action.url);
											}
										}}>{action.title}</Button>
									</>
								);
							} else {
								return <Button color={this.getButtonColor(action.type)} {...btProps}>{action.title}</Button>;
							}
						})}
					</Card.Actions>
				) : null}
			</Card>
		);
	}
}
