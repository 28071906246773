import React, { Component } from 'react';

// import { Button, Paragraph, Dialog, Portal } from 'react-native-paper';

import { Dialog } from 'material-bread';

export default class SimpleDialog extends Component<any, any> {
	state: any = {
		open: false,

		title: '',
		text: '',
		actions: [{
			title: 'Cerrar',
			onPress: this.handleClose.bind(this)
		}]
	};

	private resolve: boolean | any = false;
	private reject: boolean | any = false;

	public show(options: { title: string, text: string, actions?: { title: string, onPress: any }[] }): Promise<any> {
		return new Promise((resolve, reject) => {
			this.resolve = resolve;
			this.reject = reject;

			this.setState({
				open: true,
				title: '',
				text: '',
				actions: [{
					title: 'Cerrar',
					onPress: this.handleClose.bind(this)
				}],
				...options
			});
		});
	};

	private handleClose(event, reason: string = '') {
		try {
			this.resolve();
		} catch (e) { }

		this.reset();
	}

	public hide() {
		this.handleClose(null);
	}

	private reset() {
		this.resolve = false;
		this.reject = false;

		this.setState({
			open: false
		});
	}

	render() {
		const { open, title, text, actions } = this.state;

		return (
			<>
				{/*<Portal>
					<Dialog
						visible={open}
						onDismiss={this.handleClose.bind(this)}>
						<Dialog.Title>{title}</Dialog.Title>
						<Dialog.Content>
							<Paragraph>{text}</Paragraph>
						</Dialog.Content>
						<Dialog.Actions>
							{actions.map((r) => {
								return <Button style={{ paddingLeft: 8, paddingRight: 8 }} onPress={r.onPress}>{r.title}</Button>
							})}
						</Dialog.Actions>
					</Dialog>
				</Portal>*/}

				<Dialog
					visible={open}
					onTouchOutside={this.handleClose.bind(this)}
					title={title}
					supportingText={text}
					actionItems={actions.map((r) => {
						return { text: r.title, onPress: r.onPress }
					})}
				/>
			</>
		);
	}
}