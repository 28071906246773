import React, { Component } from 'react';
import { StyleSheet, View, ScrollView, FlatList, TouchableOpacity, ImageBackground } from 'react-native';

import Screen from './components/Screen';

import { Button, Icon } from 'material-bread';
import { Text, TextInput } from 'react-native-paper';

import * as Permissions from 'expo-permissions';
import { BarCodeScanner } from 'expo-barcode-scanner';
import { Camera } from 'expo-camera';

import * as ImageManipulator from 'expo-image-manipulator';

import MultiSelect from 'react-native-multiple-select';

import Title from './components/Title';
import Notifications from './components/Notifications';

import Card from './components/Card';
import Loading from './components/Loading';

import Requests from './helpers/Requests';
import { Platform } from '@unimodules/core';

enum DispatcherSteps {
	LOADING = 'loading',
	ENTER = 'enter',
	NO_PERMISSION = 'no_perm',
	DISPATCH = 'dispatch',
	REJECT_DISPATCH = 'reject_dispatch',
	SELECT_DISPATCH = 'select_dispatch',
	TAKE_PHOTO = 'take_photo',
};

const dispatchButtonText = 'Finalizar Conformación';
const DESIRED_RATIO = "16:9";

export default class DispatcherSyncScreen extends Component<any, any> {

	private notifications: Notifications;

	private camera: Camera;

	state: any = {
		step: DispatcherSteps.LOADING,
		scanned: false,

		dispatchNmb: '',

		generalRejectReason: -1,
		generalRejectObservation: '',

		cameraType: Camera.Constants.Type.back,
		photo: false,

		data: false,
	};

	loading: Loading;

	async componentDidMount() {
		// If web field to enter the Remito R[0000000]
		// If app permissions and QR scanner
		if (Platform.OS !== 'web') {
			// Permissions
			const { status } = await Permissions.askAsync(Permissions.CAMERA);

			this.setState({ step: status === 'granted' ? DispatcherSteps.ENTER : DispatcherSteps.NO_PERMISSION });
		} else {
			this.setState({ step: DispatcherSteps.ENTER });
		}
	}

	private async loadDispatch(id) {
		console.info(id);

		this.loading.show();
		let data = await Requests.get(`/dispatch/${id}`);

		console.info(data);

		// Prepare data.products
		data.products = data.products.map((r) => {
			return {
				...r,
				dispatch: {
					quantity: r.quantity,
					reason: -1,
					observation: ''
				}
			}
		});

		this.setState({ data: data, step: DispatcherSteps.SELECT_DISPATCH });
		// this.setState({ data: data, step: DispatcherSteps.TAKE_PHOTO });

		this.loading.hide();
	}

	private async prepareRatio() {
		if (Platform.OS === 'android' && this.camera) {
			const ratios = await this.camera.getSupportedRatiosAsync();

			// See if the current device has your desired ratio, otherwise get the maximum supported one 
			// Usually the last element of "ratios" is the maximum supported ratio 
			const ratio = ratios.find((ratio) => ratio === DESIRED_RATIO) || ratios[ratios.length - 1]; this.setState({ ratio });

			this.setState({ cameraRatio: ratio });
		}
	}

	private async createDispatch() {
		try {
			let { id, products } = this.state.data;

			this.loading.show();

			let data = await Requests.post(`/dispatch`, {
				id,
				products: products.map((r) => {
					return { id: r.id, ...r.dispatch };
				})
			});

			console.info(data);

			this.loading.hide();

			this.notifications.dialog.show({
				title: 'Conformación Finalizada',
				text: 'Quiere agregar una foto del Remito Conformado?',
				actions: [
					{
						title: 'Si', onPress: async () => {
							this.setState({ step: DispatcherSteps.TAKE_PHOTO });
						}
					},
					{
						title: 'No',
						onPress: () => {
							this.notifications.dialog.hide();

							this.goBack();
						}
					}
				]
			});
		} catch (e) {
			this.notifications.dialog.show({
				title: 'Error de Conformación',
				text: `Se produjo un error al conformar la orden.
Mensaje del Servicio:
${e.message}`
			});
		}
	}

	private reset() {
		this.setState({
			step: DispatcherSteps.ENTER,
			scanned: false,

			dispatchNmb: '',

			generalRejectReason: -1,
			generalRejectObservation: '',

			data: false,
		});
	}

	private goBack() {
		this.props.navigation.goBack();
	}

	private handleBarCodeScanned({ type, data }) {
		this.setState({ scanned: true });

		if (data.indexOf('R') === 0) {
			this.loadDispatch(data);
		} else {
			this.notifications.dialog.show({
				title: 'Revise',
				text: 'El código leído es inválido.'
			});

			this.setState({ scanned: false });
		}

		// alert(`Bar code with type ${type} and data ${data} has been scanned!`);
	};

	private setQuantity(index, value) {
		let { products } = this.state.data;

		products[index].dispatch = {
			...products[index].dispatch,
			quantity: parseFloat(products[index].quantity) < parseFloat(value) ? products[index].quantity : value
		};

		this.setState({
			products
		});
	}

	private setSelectedReason(index, value) {
		let { products } = this.state.data;

		products[index].dispatch = {
			...products[index].dispatch,
			reason: value
		};

		this.setState({
			products
		});
	}

	private setObservation(index, value) {
		let { products } = this.state.data;

		products[index].dispatch = {
			...products[index].dispatch,
			observation: value
		};

		this.setState({
			products
		});
	}

	renderEnterMethod() {
		if (Platform.OS === 'web') {
			let { dispatchNmb } = this.state;

			return (
				<View
					style={{ width: '100%', padding: 15 }}>
					<View style={{ marginBottom: 15 }}>
						<Title>Ingrese el Remito ( 0000123456789 )</Title>
					</View>

					<TextInput
						value={dispatchNmb}
						autoCorrect={false}
						disableFullscreenUI
						keyboardType={'numeric'}
						onChangeText={(val) => {
							this.setState({ dispatchNmb: val });
						}}
					/>

					<Button
						type="outlined"
						text="Iniciar"
						style={{ width: '100%', marginTop: 20 }}
						onPress={() => {
							// Only if length == 13 || 14. Add the R if not exists
							if (dispatchNmb.length === 13 || dispatchNmb.length === 14) {
								if (dispatchNmb.indexOf('R') === -1) {
									dispatchNmb = dispatchNmb.replace('R', '');
								}

								this.loadDispatch(`R${dispatchNmb}`);
							} else {
								this.notifications.snackbar.show({
									text: 'El Remito debe tener al menos 13 caracteres'
								});
							}
						}} />
				</View>
			);
		} else {
			let { scanned } = this.state;

			return (
				<View
					style={{
						display: 'flex',
						flex: 1,
						flexDirection: 'column',
						width: '100%'
					}}>

					<BarCodeScanner
						onBarCodeScanned={scanned ? undefined : this.handleBarCodeScanned.bind(this)}
						style={{ ...StyleSheet.absoluteFillObject, width: '100%', backgroundColor: 'black' }}
					/>

					{scanned && (
						<Button
							text="Intentar de nuevo"
							style={{ width: '100%', marginTop: 20 }}
							onPress={() => this.setState({ scanned: false })} />
					)}
				</View>
			)
		}
	}

	renderItem({ item, index }) {
		const { reasons } = this.state.data;
		let { description, id, uid, quantity, dispatch } = item;

		return (
			<>
				<Card
					key={uid}
					title={description}
					content={
						<>
							<View style={{ flexDirection: 'column', marginBottom: 15 }}>
								<Text style={{ flex: 1 }}>Cantidad Solicitada: {quantity}</Text>
							</View>

							<TextInput
								value={`${dispatch.quantity}`}
								autoCorrect={false}
								disableFullscreenUI
								keyboardType={'numeric'}
								onChangeText={(val) => {
									this.setQuantity(index, val);
								}}
							/>

							{dispatch.quantity != quantity && (
								<>
									<View style={{ flexDirection: 'column', marginBottom: 15, marginTop: 15 }}>
										<Text style={{ flex: 1 }}>Causa de la Diferencia</Text>
									</View>

									<MultiSelect
										hideTags
										items={reasons.map((r) => { return { id: r.ID, name: r.DESCRIPCION } })}
										uniqueKey="id"
										onSelectedItemsChange={(selectedItems) => {
											this.setSelectedReason(index, selectedItems[0]);
										}}
										selectedItems={[dispatch.reason]}
										selectText="Seleccionar causa"
										searchInputPlaceholderText="Buscar Causa..."
										onChangeInput={(text) => console.log(text)}
										tagRemoveIconColor="#CCC"
										tagBorderColor="#CCC"
										tagTextColor="#CCC"
										selectedItemTextColor="#CCC"
										selectedItemIconColor="#CCC"
										itemTextColor="#000"
										displayKey="name"
										searchInputStyle={{ color: '#CCC' }}
										styleRowList={{ paddingTop: 5, paddingBottom: 5 }}
										submitButtonColor="#CCC"
										submitButtonText="Buscar"
										single={true}
									/>

									<View style={{ flexDirection: 'column', marginBottom: 15, marginTop: 15 }}>
										<Text style={{ flex: 1 }}>Observación</Text>
									</View>

									<TextInput
										value={`${dispatch.observation}`}
										autoCorrect={false}
										disableFullscreenUI
										onChangeText={(val) => {
											this.setObservation(index, val);
										}}
									/>
								</>
							)}
						</>
					}
				/>
			</>
		);
	}

	private renderItems() {
		/*
		"client": Object {
			"address": "Av Bosques esq colectora 4521",
			"city": "Florencio Varela",
			"deliveryId": "PRINCIPAL",
			"id": "DIS048",
			"name": "ZONMAN SA",
		},
		"date": "2016-01-04T15:09:23.000Z",
		"id": "R0000600040597",
		"products": Array [
			Object {
			"description": "MANTECA TONADITA 200 gr 6 Kg 30 U",
			"id": "101011010",
			"quantity": 30,
			},
		],
		"reasons": Array [
			Object {
			"DESCRIPCION": "NO PEDIDO",
			"ID": 1,
			},
			Object {
			"DESCRIPCION": "SOBRE STOCK",
			"ID": 2,
			},
		],
		"state": "F",
		*/
		let { id, client, products } = this.state.data;

		return (
			<>
				<View
					style={{ width: '100%', padding: 15, borderBottomColor: '#EAEAEA', borderBottomWidth: 1 }}>
					<View>
						<Title>Remito / Cliente</Title>
						<Text>{id} / {client.name}</Text>
					</View>
					<Button
						type="outlined"
						text={dispatchButtonText}
						style={{ width: '100%', marginTop: 20 }}
						onPress={() => {
							this.createDispatch();
						}} />
				</View>

				<FlatList
					style={{ width: '100%' }}
					keyboardShouldPersistTaps={'always'}
					showsVerticalScrollIndicator={false}
					data={products}
					keyExtractor={(item: any) => item.uid}
					renderItem={this.renderItem.bind(this)}
				/>
			</>
		);
	}

	renderSelectDispatch() {
		return (
			<ScrollView
				style={{ width: '100%', padding: 15 }}>

				<View style={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', width: '100%' }}>
					<Button
						type="flat"
						color="green"
						textColor="white"
						text="Aceptar Todo el Pedido"
						style={{ width: '100%', marginTop: 20, height: 60 }}
						onPress={async () => {
							await this.createDispatch();
						}} />

					<Button
						type="flat"
						color="red"
						textColor="white"
						text="Rechazar Todo el Pedido"
						style={{ width: '100%', marginTop: 20, height: 60 }}
						onPress={() => {
							// Mostrar pantalla para elegir el motivo y poner la observación
							this.setState({ step: DispatcherSteps.REJECT_DISPATCH });
						}} />

					<Button
						type="flat"
						color="black"
						textColor="white"
						text="Conformación Parcial del Pedido"
						style={{ width: '100%', marginTop: 20, height: 60 }}
						onPress={() => {
							// this.createDispatch();
							this.setState({ step: DispatcherSteps.DISPATCH });
						}} />
				</View>
			</ScrollView>
		);
	}

	renderRejectDispatch() {
		const { generalRejectReason, generalRejectObservation } = this.state,
			{ id, client, products, reasons } = this.state.data;

		return (
			<>
				<ScrollView
					style={{ width: '100%', padding: 15, borderBottomColor: '#EAEAEA', borderBottomWidth: 1 }}>
					<View>
						<Title>Remito / Cliente</Title>
						<Text>{id} / {client.name}</Text>
					</View>
					<Button
						type="outlined"
						text={dispatchButtonText}
						style={{ width: '100%', marginTop: 20 }}
						onPress={() => {
							// Set Reason and Observation in all items before proceed

							for (let i = 0; i < products.length; i++) {
								this.setQuantity(i, 0);

								this.setSelectedReason(i, generalRejectReason);

								this.setObservation(i, generalRejectObservation);
							}

							// Create
							this.createDispatch();
						}} />

					<View>
						<View style={{ flexDirection: 'column', marginBottom: 15, marginTop: 15 }}>
							<Text style={{ flex: 1, color: 'black' }}>Causa del Rechazo</Text>
						</View>

						<MultiSelect
							hideTags
							items={reasons.map((r) => { return { id: r.ID, name: r.DESCRIPCION } })}
							uniqueKey="id"
							onSelectedItemsChange={(selectedItems) => {
								this.setState({ generalRejectReason: selectedItems[0] });
							}}
							selectedItems={[generalRejectReason]}
							selectText="Seleccionar causa"
							searchInputPlaceholderText="Buscar Causa..."
							onChangeInput={(text) => console.log(text)}
							tagRemoveIconColor="#CCC"
							tagBorderColor="#CCC"
							tagTextColor="#CCC"
							selectedItemTextColor="#CCC"
							selectedItemIconColor="#CCC"
							itemTextColor="#000"
							displayKey="name"
							searchInputStyle={{ color: '#CCC' }}
							styleRowList={{ paddingTop: 5, paddingBottom: 5 }}
							submitButtonColor="#CCC"
							submitButtonText="Buscar"
							single={true}
						/>

						<View style={{ flexDirection: 'column', marginBottom: 15, marginTop: 15 }}>
							<Text style={{ flex: 1, color: 'black' }}>Observación</Text>
						</View>

						<TextInput
							value={`${generalRejectObservation}`}
							autoCorrect={false}
							disableFullscreenUI
							onChangeText={(val) => {
								this.setState({ generalRejectObservation: val });
							}}
						/>
					</View>
				</ScrollView>
			</>
		);
	}

	renderCamera() {
		const { cameraType, cameraRatio, photo, data } = this.state;

		return (
			<View style={{ flex: 1, width: '100%', display: 'flex', flexDirection: 'column' }}>
				{!!photo ? (
					<ImageBackground style={{ width: '100%', flex: 1 }} source={{ uri: photo.uri }}>
						<View
							style={{
								flex: 1,
								backgroundColor: 'transparent',
								flexDirection: 'column',
								display: 'flex',
								width: '100%',
								flexWrap: 'wrap'
							}}>
							<View style={{ flexGrow: 1 }}></View>
							<View style={{
								width: '100%',
								backgroundColor: 'rgba(255, 255, 255, 0.4)',
								display: 'flex',
								flexDirection: 'row'
							}}>
								<TouchableOpacity
									style={{
										padding: 12,
										flex: 1,
										alignItems: 'center'
									}}
									onPress={async () => {
										this.loading.show();

										let image = await ImageManipulator.manipulateAsync(photo.uri, [{
											resize: { width: 800 }
										}], {
											compress: 0.75,
											format: ImageManipulator.SaveFormat.JPEG,
											base64: true
										});

										await Requests.post(`/dispatch/${data.id}/scan`, {
											image: image.base64
										});

										this.loading.hide();

										this.goBack();
									}}>
									<Icon
										name="check"
										size={24}
										color="green" />
								</TouchableOpacity>
								<TouchableOpacity
									style={{
										padding: 12
									}}
									onPress={() => {
										this.setState({ photo: false });
									}}>
									<Icon
										name="close"
										size={24}
										color="red" />
								</TouchableOpacity>
							</View>
						</View>
					</ImageBackground>
				) : (


						<Camera
							style={{ width: '100%', flex: 1 }}
							type={cameraType}
							useCamera2Api={true}
							autoFocus={true}
							ratio={cameraRatio}
							onCameraReady={this.prepareRatio.bind(this)}
							ref={(ref) => this.camera = ref}
						>
							<View
								style={{
									flex: 1,
									backgroundColor: 'transparent',
									flexDirection: 'column',
									display: 'flex',
									width: '100%',
									flexWrap: 'wrap'
								}}>
								<View style={{ flexGrow: 1 }}></View>
								<View style={{
									width: '100%',
									backgroundColor: 'rgba(255, 255, 255, 0.4)',
									display: 'flex',
									flexDirection: 'row'
								}}>
									<TouchableOpacity
										style={{
											padding: 12,
											flex: 1,
											alignItems: 'center'
										}}
										onPress={async () => {
											if (this.camera) {
												let photo = await this.camera.takePictureAsync({
													quality: 0.7
												});

												this.setState({ photo });
											}
										}}>
										<Icon
											name="camera"
											size={24}
											color="black" />
									</TouchableOpacity>
									<TouchableOpacity
										style={{
											padding: 12
										}}
										onPress={() => {
											this.setState({
												cameraType: cameraType === Camera.Constants.Type.back
													? Camera.Constants.Type.front
													: Camera.Constants.Type.back
											});
										}}>
										<Icon
											name={cameraType === Camera.Constants.Type.back
												? 'camera-front'
												: 'camera-rear'}
											size={24}
											color="black" />
									</TouchableOpacity>
								</View>
							</View>
						</Camera>
					)}
			</View>
		);
	}

	render() {
		const { step } = this.state;

		return (
			<Screen appBar={{
				hasBack: true,
				onBackPress: this.goBack.bind(this),
				title: "Conformación de Remito"
			}}>

				<View style={styles.container}>
					{step === DispatcherSteps.ENTER && this.renderEnterMethod()}
					{step === DispatcherSteps.SELECT_DISPATCH && this.renderSelectDispatch()}
					{step === DispatcherSteps.REJECT_DISPATCH && this.renderRejectDispatch()}
					{step === DispatcherSteps.DISPATCH && this.renderItems()}
					{step === DispatcherSteps.TAKE_PHOTO && this.renderCamera()}
				</View>

				<Loading ref={(ref) => this.loading = ref} />

				<Notifications ref={ref => this.notifications = ref} />
			</Screen>
		);
	}
}

const styles = StyleSheet.create({
	container: {
		display: 'flex',
		flex: 1,
		backgroundColor: '#fff',
		alignItems: 'center',
		// justifyContent: 'center',
		padding: 0,
	},
});
