import * as React from 'react';

import { Platform, StatusBar } from 'react-native';

// import { Appbar } from 'react-native-paper';

import { Appbar } from 'material-bread';

export interface IAppBarProps {
	title: string;
	subtitle?: string;
	hasBack?: boolean;
	onBackPress?: any;
	actions?: { icon?: any, onPress: any }[];

}

export default function AppBar(props: IAppBarProps) {
	/*
	[
					{ name: 'search', onPress: () => console.log('onSearch') },
					{ name: 'more-vert' },
				]
	*/
	return (
		<>
			{Platform.OS == 'ios' && <StatusBar barStyle="light-content" />}

			{/*<Appbar.Header
			dark={true}
			statusBarHeight={Platform.OS == 'ios' ? 40 : 0}>
			{props.hasBack && (
				<Appbar.BackAction onPress={props.onBackPress} />
			)}
			<Appbar.Content
				title={props.title}
				subtitle={props.subtitle}
			/>
			{props.actions && props.actions.map(r => {
				return (
					<Appbar.Action icon={r.icon} onPress={r.onPress} />
				);
			})}
		</Appbar.Header>*/}

			<Appbar
				barType={"normal"}
				title={props.title}
				subtitle={props.subtitle}
				navigation={props.hasBack ? 'arrow-back' : false}
				onNavigation={props.onBackPress}
				actionItems={props.actions ? props.actions.map(r => {
					return { name: r.icon, onPress: r.onPress };
				}) : []}
			/>

		</>
	);
}
