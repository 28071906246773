import React from 'react';

import { DefaultTheme, Provider as PaperProvider } from 'react-native-paper';
import { BreadProvider } from "material-bread";

import { createStackNavigator, createAppContainer } from 'react-navigation';

import * as Font from 'expo-font';

import User from './src/helpers/User';

import Clients from './src/sync/clients';
import Pricelists from './src/sync/pricelists';
import OrderSync from './src/sync/OrderSync';
import HomeSync from './src/sync/home';

import LoginScreen from './src/login';
import HomeScreen from './src/home';
import NewOrderScreen from './src/NewOrder';
import OrdersScreen from './src/Orders';
import ClientsScreen from './src/Clients';
import ProductsScreen from './src/Products';
import PricelistsScreen from './src/Pricelists';
import OrdersSyncScreen from './src/OrdersSync';
import DispatcherScreen from './src/Dispatcher';
import DispatchesScreen from './src/Dispatches';
import ImpersonatorScreen from './src/Impersonator';

import ListScreen from './src/ListScreen';

export const theme = {
	...DefaultTheme,
	roundness: 2,
	colors: {
		...DefaultTheme.colors,
		primary: '#3498db',
		accent: '#f1c40f',
	}
};

class App extends React.Component {
	props: any;
	state = {
		data: {}
	};

	async componentDidMount() {
		await Font.loadAsync({
			'MaterialIcons': require('./assets/fonts/MaterialIcons.ttf'),
		});
		// Check user and render Scan or Home
		let user = await User.get();

		if (!user) {
			this.props.navigation.replace('Login');
		} else {
			Clients.start();
			Pricelists.start();
			OrderSync.start();
			HomeSync.start();

			this.props.navigation.replace('Home');
		}
	}

	stateListener(state) {
		this.setState(state);
	}

	render() {
		return null;
	}
}

let AppStack = createStackNavigator({
	AppLoading: App,
	Login: LoginScreen,
	Home: HomeScreen,
	NewOrder: NewOrderScreen,
	Orders: OrdersScreen,
	Clients: ClientsScreen,
	Products: ProductsScreen,
	Pricelists: PricelistsScreen,
	OrdersSync: OrdersSyncScreen,
	Dispatcher: DispatcherScreen,
	Dispatches: DispatchesScreen,
	Impersonator: ImpersonatorScreen,
	ListScreen
}, {
	headerMode: 'none'
});

let AppContainer = createAppContainer(AppStack);

export default function Main() {
	return (
		<BreadProvider>
			<PaperProvider theme={theme}>
				<AppContainer />
			</PaperProvider>
		</BreadProvider>
	);
}