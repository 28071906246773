import * as React from 'react';

import { SafeAreaView, StatusBar, View } from 'react-native';

import { NavigationEvents } from 'react-navigation';

import Constants from 'expo-constants';

import AppBar, { IAppBarProps } from '../AppBar';

export interface IScreenProps {
	appBar?: IAppBarProps;
	onDidFocus?: any;
	children: any;
}

export default function Screen(props: IScreenProps) {
	return (
		<>
			<NavigationEvents
				onDidFocus={() => {
					props?.onDidFocus?.();
				}}
			/>

			<StatusBar backgroundColor={"0079B3"} barStyle="dark-content" />

			<View style={{ backgroundColor: "#0079B3", height: Constants.statusBarHeight + 2, width: '100%', position: 'absolute' }}></View>

			<SafeAreaView style={{ flex: 1 }}>

				{props?.appBar && (
					<AppBar {...props.appBar} />
				)}

				{props.children}

			</SafeAreaView>
		</>
	);
}
