import Requests from '../helpers/Requests';

import {
	AsyncStorage
} from 'react-native';
import User from '../helpers/User';

export default class SyncClient {
	interval: any;

	public clientName: String = '';

	public url: string = ``;
	public requestType: string = 'GET';
	public storageKey: string = '';
	public maxDuration: number = (10 * (60 * 1000));
	public syncInterval: number = 0.5 * (60 * 1000);

	public isSyncing: boolean = false;

	constructor(clientName) {
		// Assign client Namme
		this.clientName = clientName;

		// Listen for user
		User.listen(this.userListener.bind(this));
	}

	public start() {
		this.syncCycle();

		console.info(`Setting interval of ${this.syncInterval} for client ${this.clientName}`);
		this.interval = setInterval(this.syncCycle.bind(this), this.syncInterval);
	}

	public async get() {
		return await this.sync();
	}

	private userListener(event) {
		if (event === 'set') {
			this.sync();
		}
	}

	private async syncCycle() {
		console.info(`Starting sync for client ${this.clientName}`);

		if(this.isSyncing) {
			console.info(`Sync for ${this.clientName} is Already Running...`);
			return false;
		}
		
		this.isSyncing = true;

		// Run Sync
		try {
			console.info(`Running sync for client ${this.clientName}`);

			await this.sync();
		} catch(e) {}

		console.info(`Sync finished for client ${this.clientName}`);
		this.isSyncing = false;
	}

	public async sync(force = false) {
		try {
			let user = await User.get();

			if (!user) {
				return console.info('No User, cant sync');
			}

			if (this.storageKey === '') {
				return console.info('No Storage Key');
			}

			console.info(`Getting data for key ${this.storageKey}`);

			let storedData = await AsyncStorage.getItem(this.storageKey);
			
			if (force === false && storedData && storedData !== null && storedData !== undefined) {
				let { createdAt, data } = JSON.parse(storedData);

				if (((new Date()).getTime() - createdAt) >= this.maxDuration) {
					try {
						return await this.resync();
					} catch (e) {
						return data;
					}
				} else {
					return data;
				}
			} else {
				return await this.resync();
			}
		} catch (e) {
			console.info(e);
		}

		return [];
	}

	private async resync() {
		try {
			let res = await Requests.get(this.url);

			if (res && res !== null && res !== undefined) {
				let dataToStore = { createdAt: ((new Date).getTime()), data: res };

				await AsyncStorage.setItem(this.storageKey, JSON.stringify(dataToStore));

				return res;
			}
		} catch (e) {
			console.info('Error syncing', e);
			throw new Error('ERD');
		}
	}
}