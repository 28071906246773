import Requests from '../helpers/Requests';

import {
	AsyncStorage
} from 'react-native';

import Constants from 'expo-constants';

import User from '../helpers/User';

import shortid from 'shortid';

import SyncClient from './SyncClient';

class OrderSync extends SyncClient {
	interval: any;

	public states = {
		READY: 'READY',
		LOCKED: 'LOCKED',
		IN_SYNC: 'IN_SYNC',
		SYNCED: 'SYNCED'
	};

	public url: string = ``;
	public requestType: string = 'GET';
	public storageKey: string = '_syncOrders';
	public maxDuration: number = (10 * (60 * 1000));
	public syncInterval: number = 0.5 * (60 * 1000);

	private listener: any = false;

	private async getFromStorage(): Promise<any[]> {
		let storedData = await AsyncStorage.getItem(this.storageKey);

		if (storedData && storedData !== null && storedData !== undefined) {
			return JSON.parse(storedData);
		}

		return [];
	}

	private async saveToStorage(data) {
		this.listener && this.listener('orders_changed');

		if (data && Array.isArray(data)) {
			return AsyncStorage.setItem(this.storageKey, JSON.stringify(data));
		}

		return true;
	}

	private async removeByKey(key) {
		let stored = await this.getFromStorage(),
			idx = stored.findIndex((r) => r.key === key);

		stored.splice(idx, 1);

		return this.saveToStorage(stored);
	}

	private async addStatus(key, status) {
		let stored = await this.getFromStorage(),
			idx = stored.findIndex((r) => r.key === key);

		stored[idx].status = [...stored[idx].status || [], status];

		return this.saveToStorage(stored);
	}

	private async update(key, data = {}) {
		let stored = await this.getFromStorage(),
			idx = stored.findIndex((r) => r.key === key);

		// Forbidden Keys
		['key', 'status'].map(k => {
			if (!!data[k]) {
				delete data[k];
			}
		})

		stored[idx] = {
			...stored[idx],
			...data
		};

		return this.saveToStorage(stored);
	}

	private async createHeaders() {
		return {
			'x-device-name': Constants.deviceName,
			'x-device-year-class': Constants.deviceYearClass,
			'x-app-version': Constants.nativeAppVersion,
			'x-app-manifest-version': Constants.manifest.version,
			'x-app-build-version': Constants.nativeBuildVersion,
			'x-app-session-id': Constants.sessionId
		};
	}

	private async unlock() {
		// Iterate the locked
		let orders = await this.all([this.states.LOCKED]);

		for(let order of orders) {
			if(order && order.created) {
				let elapsedTime = (new Date()).getTime() - order.created;

				if(elapsedTime > (60 * 5)) {
					await this.update(order.key, {
						state: this.states.READY
					});
				}
			}
		}
	}

	private async clean() {
		// Iterate the locked
		let orders = await this.all([this.states.SYNCED]);

		for(let order of orders) {
			await this.remove(order.key);
		}
	}

	public listen(listener) {
		this.listener = listener;
	}

	public async generateKey() {
		let user = await User.get();

		return `app:order:${user.id}:${shortid.generate()}:${(new Date()).getTime()}`;
	}

	public async add(order, state = this.states.READY) {
		let stored = await this.getFromStorage(),
			key = await this.generateKey(),
			newOrder = {
				key,
				order,
				state,
				created: (new Date()).getTime(),
				status: [] // Save sync tries or errors
			}

		stored.push(newOrder);

		this.saveToStorage(stored);

		return newOrder;
	}

	public async remove(key: string) {
		return this.removeByKey(key);
	}

	public async all(states = []) {
		return (await this.getFromStorage()).filter((r) => {
			return !r.state || states.length === 0 || states.indexOf(r.state) !== -1;
		});
	}

	public async send(order) {
		try {
			await this.update(order.key, {
				state: this.states.IN_SYNC
			});

			let headers = await this.createHeaders();

			let res = await Requests.post('/order', order, headers, {
				timeout: 10000
			});

			if (res && res.status) {
				if (res.status === -9 || res.status === 0) {
					// Special case for remove
					// Is duplicated on porpuse
					// await this.remove(order.key);
					await this.update(order.key, {
						state: this.states.SYNCED
					});	

					return true;
				} else {
					await this.addStatus(order.key, res);
				}
			} else {
				await this.addStatus(order.key, res);
			}
		} catch (e) {
			console.info(e);

			await this.addStatus(order.key, e.message);
		}

		await this.update(order.key, {
			state: this.states.READY
		});

		return false;
	}

	public async sync() {
		// Clean orders
		await this.clean();

		// Check Locked Orders
		await this.unlock();
		
		console.info('::: Override Sync:::');
		console.info('Sync ORDERS');
		let orders = await this.all([this.states.READY]);

		// Promise.all
		// Solo eliminar si status > 0 || -1
		// Si hay error guardarlo en el array y permitir hacer Share en SyncOrders
		// Enviar header con info del dispo

		for (let order of orders) {
			await this.send(order);
			/*try {
				let res = await this.send(order);

				if (res && res.status) {
					if (res.status == -9) {
						// Special case for remove
						// Is duplicated on porpuse
						await this.remove(order.key);
					} else {
						// Remove if it's ok!
						await this.remove(order.key);
					}
				} else {
					await this.addStatus(order.key, res);
				}
			} catch (e) {
				console.info(e);

				await this.addStatus(order.key, e.message);
			}*/
		}
	}
}

export default new OrderSync('OrderSync');