import React, { Component } from 'react';
import { StyleSheet, View, Image, Platform } from 'react-native';

import { TextInput, Button, Text } from 'react-native-paper';

import { Notifications } from 'expo';
import * as Permissions from 'expo-permissions';

import Screen from './components/Screen';

import Requests from './helpers/Requests';
import User from './helpers/User';

import AppNotifications from './components/Notifications';

import HomeSync from './sync/home';

import Constants from 'expo-constants';

export default class LoginScreen extends Component {
	notifications: AppNotifications;

	props: any;

	state = {
		username: '', // '21962657',
		password: '' //'2657'
	};

	componentDidMount() {

	}

	async doLogin() {
		try {
			let { username, password } = this.state;

			if (username.trim() === '' || password.trim() === '') {
				return this.notifications.dialog.show({
					title: 'Elcor',
					text: 'Debe ingresar usuario y contraseña para ingresar'
				});
			}

			let body: any = {
				username,
				password
			};

			if (Platform.OS !== "web") {
				const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS);

				let finalStatus = status;

				if (status !== 'granted') {
					// Android remote notification permissions are granted during the app
					// install, so this will only ask on iOS
					const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS);
					finalStatus = status;
				}

				// Stop here if the user did not grant permissions
				if (finalStatus !== 'granted') {
					return this.notifications.dialog.show({
						title: 'Elcor',
						text: 'Debe aceptar los permisos para continuar'
					});
				}

				body.pushId = await Notifications.getExpoPushTokenAsync();
			}

			let res = await Requests.post('/user/login', body);

			await User.set(res);
			await HomeSync.sync(true);

			this.props.navigation.replace('Home');
		} catch (e) {
			this.notifications.dialog.show({
				title: 'Elcor',
				text: e.message
			});
		}
	}

	render() {
		let { username, password } = this.state;

		return (
			<Screen>
				<View style={styles.container}>
					<Image
						style={{ height: 50, resizeMode: 'contain', marginBottom: 40, marginTop: 20 }}
						source={require('./images/logo.png')} />

					<TextInput
						style={{ width: '100%', marginBottom: 10 }}
						label="Usuario"
						value={username}
						onChangeText={(username) => this.setState({ username })}
					/>

					<TextInput
						style={{ width: '100%' }}
						label="Contraseña"
						value={password}
						secureTextEntry
						onChangeText={(password) => this.setState({ password })}
					/>

					<Button
						mode="outlined"
						style={{ width: '100%', marginTop: 20 }}
						onPress={this.doLogin.bind(this)}>Ingresar</Button>

					<Text style={{ marginTop: 15, width: '100%', textAlign: 'center' }}>Versión: {Constants.manifest.version}</Text>

				</View>

				<AppNotifications ref={ref => this.notifications = ref} />
			</Screen>
		);
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: '#fff',
		alignItems: 'center',
		// justifyContent: 'center',
		padding: 20,
		paddingTop: 80
	},
});
