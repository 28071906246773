import React, { Component } from 'react';
import { Share, StyleSheet, View, FlatList } from 'react-native';

import Modal from "./components/Modal";

import { Button, Searchbar, Text, Colors } from 'react-native-paper';

import Screen from './components/Screen';

import Title from './components/Title';
import Notifications from './components/Notifications';

import Fuse from 'fuse.js';

import Card from './components/Card';
import Loading from './components/Loading';

import OrderSync from './sync/OrderSync';

export default class OrdersSyncScreen extends Component<any, any> {
	private data: any[];
	private fuse: any;
	private searchDefer: any = null;

	private notifications: Notifications;

	state: any = {
		data: [],
		order: false,
		isModalVisible: false,
		isLoadingVisible: true,

		searchText: ''
	};

	loading: Loading;

	async componentDidMount() {
		this.loadData();
	}

	private async loadData() {
		this.loading.show();
		let data = await OrderSync.all();

		// Copy into data for seach
		this.data = [...data];

		this.fuse = new Fuse(this.data, {
			shouldSort: true,
			tokenize: true,
			threshold: 0.4,
			location: 0,
			distance: 100,
			maxPatternLength: 32,
			minMatchCharLength: 1,
			keys: ['order.client.name'],
		});

		this.setState({ data: this.data });

		this.loading.hide();
	}

	private goBack() {
		this.props.navigation.goBack();
	}

	private async getProduct(product) {
		console.info(product);
		this.setState({
			isModalVisible: true,
			client: product
		});
	}

	private async runSearch() {
		let { searchText } = this.state,
			results = this.fuse.search(searchText);

		await this.setState({ data: results.map(r => r.item) });
	}

	private async clearSearch() {
		await this.setState({ data: this.data });
	}

	private async remove(item) {
		this.notifications.dialog.show({
			title: 'Eliminar Órden',
			text: 'Está seguro que quiere eliminar la Órden? Esta acción no puede cancelarse',
			actions: [
				{
					title: 'Si', onPress: async () => {
						await OrderSync.remove(item.key);

						this.notifications.dialog.hide()
						this.loadData();
					}
				},
				{
					title: 'No',
					onPress: () => this.notifications.dialog.hide()
				}
			]
		});
	}

	renderItem({ item }) {
		let { order, status } = item;

		return (
			<>
				<Card
					key={item.key}
					title={order.client.name}
					content={(
						<>
							<Text>Productos: {order.items.length}</Text>
							<Text>Descuento {parseFloat(order.discount).toFixed(2)}%</Text>
						</>
					)}
					left={null}
					actions={[
						(status && status.length > 0) ? {
							title: 'Enviar Estado',
							onPress: async () => {
								await Share.share({
									title: 'Estados',
									message: JSON.stringify(status)
								});
							}
						} : null, {
							title: 'Eliminar',
							onPress: async () => {
								console.info('Details: ', item);

								this.remove(item);
							}
						}]}
				/>
			</>
		);
	}

	private renderItems() {
		let { data } = this.state;

		return (
			<>
				<View>

				</View>

				<FlatList
					keyboardShouldPersistTaps={'always'}
					showsVerticalScrollIndicator={false}
					data={data}
					renderItem={this.renderItem.bind(this)}
					keyExtractor={(item: any, i) => item.key}
					style={{ width: '100%' }}
				/>
			</>
		);
	}

	private hideModal() {
		this.setState({
			isModalVisible: false
		});
	}

	renderDetailsModal() {
		let { client } = this.state;

		if (!client) return null;

		/*
		*/

		let { nombre, CUIT, email, credito, descuento, inhabilitado, saldo_elcor } = client;

		return (
			<Modal
				isVisible={this.state.isModalVisible}
				onBackButtonPress={this.hideModal.bind(this)}
				onBackdropPress={this.hideModal.bind(this)}
				style={{ margin: 10, padding: 0 }}>
				<View style={{ flexDirection: 'column', backgroundColor: 'white', borderRadius: 10 }}>
					<View
						style={{ width: '100%', padding: 15, borderBottomColor: '#EAEAEA', borderBottomWidth: 1 }}>
						<Title>{nombre}</Title>
						<Text>CUIT: {CUIT}</Text>
						<Text>Email: {email}</Text>
						<Text style={{ color: inhabilitado == 'N' ? Colors.green500 : Colors.red500, fontWeight: 'bold', marginBottom: 12 }}>Inhabilitado: {inhabilitado == 'N' ? 'No' : 'Si'}</Text>
						<Text>Descuento: {descuento}%</Text>
						<Text>Crédito: $ {credito}</Text>
						<Text style={{ fontWeight: 'bold' }}>Saldo: $ {saldo_elcor}</Text>
					</View>

					{/* Show orders from client */}

					<View
						style={{ width: '100%', marginTop: 0 }}>
						<Button
							style={{ padding: 15 }}
							onPress={this.hideModal.bind(this)}>Cerrar</Button>
					</View>
				</View>
			</Modal>
		);
	}

	render() {
		return (
			<Screen appBar={{
				hasBack: true,
				onBackPress: this.goBack.bind(this),
				title: "Órdenes en Sincronización"
			}}>

				<Searchbar
					placeholder="Buscar..."
					onChangeText={async (query) => {
						await this.setState({ searchText: query });

						if (query.length === 0) {
							await this.clearSearch();
							clearTimeout(this.searchDefer);
							return;
						}

						// Run Search

						if (this.searchDefer) {
							clearTimeout(this.searchDefer);
						}

						this.searchDefer = setTimeout(async () => {
							await this.runSearch();
						}, 100);
					}}
					value={this.state.searchText}
				/>

				<View style={styles.container}>
					{this.renderItems()}
				</View>

				{this.renderDetailsModal()}

				<Loading ref={(ref) => this.loading = ref} />

				<Notifications ref={ref => this.notifications = ref} />
			</Screen>
		);
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: '#fff',
		alignItems: 'center',
		// justifyContent: 'center',
		padding: 0,
	},
});
