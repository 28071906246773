import React, { Component } from 'react';

import { StyleSheet } from 'react-native';
import { Portal, Snackbar } from 'react-native-paper';

export default class SimpleSnackbar extends Component<any, any> {
	state: any = {
		open: false,

		text: '',
		autoHide: 3000
	};

	private resolve: boolean | any = false;
	private reject: boolean | any = false;

	public show(options: { text: string, autoHide?: number }): Promise<any> {
		return new Promise((resolve, reject) => {
			this.resolve = resolve;
			this.reject = reject;

			this.setState({
				open: true,
				text: '',
				...options
			});
		});
	};

	public hide() {
		this.handleClose(null);
	}

	private handleClose(event, reason: string = '') {
		try {
			this.resolve();
		} catch (e) { }

		this.reset();
	}

	private reset() {
		this.resolve = false;
		this.reject = false;

		this.setState({ open: false });
	}

	render() {
		const { classes } = this.props,
			{ open, text, autoHide } = this.state;

		return (
			<Portal>
				<Snackbar
					visible={open}
					onDismiss={this.handleClose.bind(this)}
					duration={autoHide}
				>
					{text}
				</Snackbar>
			</Portal>
		);
	}
}