import React, { Component } from 'react';
import { StyleSheet, View, FlatList } from 'react-native';

import { Searchbar, Text } from 'react-native-paper';

import Screen from './components/Screen';

import Fuse from 'fuse.js';

import Requests from './helpers/Requests';
import Card from './components/Card';
import Loading from './components/Loading';
import ProductDialog from './components/ProductDialog';

export default class ProdudctScreen extends Component<any, any> {
	private data: any[];
	private fuse: any;
	private searchDefer: any = null;

	state: any = {
		data: [],
		order: false,
		isModalVisible: false,
		isLoadingVisible: true,

		searchText: ''
	};

	loading: Loading;
	productDialog: ProductDialog;

	async componentDidMount() {
		this.loading.show();
		let data = await Requests.get('/products', {});

		// Copy into data for seach
		this.data = [...data];

		this.fuse = new Fuse(this.data, {
			shouldSort: true,
			tokenize: true,
			threshold: 0.4,
			location: 0,
			distance: 100,
			maxPatternLength: 32,
			minMatchCharLength: 1,
			keys: ['id_producto', 'COD_BARRA', 'Producto'],
		});

		this.setState({ data: this.data });

		this.loading.hide();
	}

	private goBack() {
		this.props.navigation.goBack();
	}

	private async getProduct(product) {
		this.productDialog.show(product);
	}

	private async runSearch() {
		let { searchText } = this.state,
			results = this.fuse.search(searchText);

		await this.setState({ data: results.map(r => r.item) });
	}

	private async clearSearch() {
		await this.setState({ data: this.data });
	}

	renderItem({ item }) {
		return (
			<>
				<Card
					key={item.id}
					title={item.Producto}
					content={(
						<>
							<Text>Cod. Barra: {item.COD_BARRA}</Text>
							<Text>Cod. Caja: {item.cod_caja}</Text>
						</>
					)}
					left={null}
					actions={[{
						title: 'Ver Producto',
						onPress: async () => {
							console.info('Details: ', item);

							await this.getProduct(item);
						}
					}]}
				/>
			</>
		);
	}

	private renderItems() {
		let { data } = this.state;

		return (
			<>
				<View>

				</View>

				<FlatList
					keyboardShouldPersistTaps={'always'}
					showsVerticalScrollIndicator={false}
					data={data}
					renderItem={this.renderItem.bind(this)}
					keyExtractor={(item: any, i) => item.id}
					style={{ width: '100%' }}
				/>
			</>
		);
	}

	private hideModal() {
		this.setState({
			isModalVisible: false
		});
	}

	render() {
		return (
			<Screen appBar={{
				hasBack: true,
				onBackPress: this.goBack.bind(this),
				title: "Listado de Productos"
			}}>

				<Searchbar
					placeholder="Buscar..."
					onChangeText={async (query) => {
						await this.setState({ searchText: query });

						if (query.length === 0) {
							await this.clearSearch();
							clearTimeout(this.searchDefer);
							return;
						}

						// Run Search

						if (this.searchDefer) {
							clearTimeout(this.searchDefer);
						}

						this.searchDefer = setTimeout(async () => {
							await this.runSearch();
						}, 100);
					}}
					value={this.state.searchText}
				/>

				<View style={styles.container}>
					{this.renderItems()}
				</View>

				<Loading ref={(ref) => this.loading = ref} />
				<ProductDialog ref={(ref) => this.productDialog = ref} />
			</Screen>
		);
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: '#fff',
		alignItems: 'center',
		// justifyContent: 'center',
		padding: 0,
	},
	imageContain: {
		width: '80%',
		height: 200,
		margin: 15,
		resizeMode: 'contain',
		alignSelf: 'center'
	}
});
