import SyncClient from './SyncClient';

class Clients extends SyncClient {
	public url: string = `/clients`;
	public requestType: string = 'GET';
	public storageKey: string = 'clients';
	public maxDuration: number = (10 * (60 * 1000));
	public syncInterval: number = 0.5 * (60 * 1000);
}

export default new Clients('Clients');