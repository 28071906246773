import React, { Component } from 'react';
import { StyleSheet, View, FlatList } from 'react-native';

import { Searchbar, Text } from 'react-native-paper';

import Screen from './components/Screen';

import Fuse from 'fuse.js';

import Requests from './helpers/Requests';
import Card from './components/Card';
import Loading from './components/Loading';
import Notifications from './components/Notifications';

export default class ListScreen extends Component<any, any> {
	private data: any[];
	private fuse: any;
	private searchDefer: any = null;

	state: any = {
		title: 'Cargando...',

		data: [],

		search: false,

		searchText: ''
	};

	loading: Loading;
	notifications: Notifications;

	async componentDidMount() {
		let { params } = this.props.navigation.state;

		this.setState({ title: params.title ?? 'Cargando... ' });

		this.loadData();
	}

	private async loadData() {
		let { params } = this.props.navigation.state;

		this.loading.show();

		let data: any = { search: false, list: [] };

		if (params.action.method == 'GET') {
			data = await Requests.get(params.action.url, {});
		} else {
			data = await Requests.post(params.action.url, params.action.body, {});
		}

		// Copy into data for seach
		this.data = [...data.list];

		this.fuse = new Fuse(this.data, {
			shouldSort: true,
			tokenize: true,
			threshold: 0.4,
			location: 0,
			distance: 100,
			maxPatternLength: 32,
			minMatchCharLength: 1,
			keys: data?.search?.keys ?? [],
		});

		this.setState({ search: data.search, data: this.data });

		this.loading.hide();
	}

	private goBack() {
		this.props.navigation.goBack();
	}

	private async runSearch() {
		let { searchText } = this.state,
			results = this.fuse.search(searchText);

		await this.setState({ data: results.map(r => r.item) });
	}

	private async clearSearch() {
		await this.setState({ data: this.data });
	}

	private showWarning(data) {
		return new Promise((resolve, reject) => {
			this.notifications.dialog.show({
				...data,
				actions: [
					{
						title: 'Si', onPress: async () => {
							this.notifications.dialog.hide();

							resolve();
						}
					},
					{
						title: 'No',
						onPress: () => {
							this.notifications.dialog.hide();

							reject();
						}
					}
				]
			});
		});
	}

	private async executeAction(action) {
		try {
			if (action.warning !== false) {
				await this.showWarning(action.warning);
			}

			if (action.type == 'HTTP') {
				try {
					this.loading.show();

					let res = await Requests.get(action.url, {});

					await this.notifications.dialog.show({
						title: 'Acción ejecutada',
						text: res.message ? res.message : 'La acción fue ejecutada con éxito.'
					});

					this.loadData();
				} catch (e) {
					await this.notifications.dialog.show({
						title: 'Error',
						text: e.error ? e.error : 'Se produjo un error al ejecutar la acción.'
					});
				}

				this.loading.hide();
			}
		} catch (e) { }
	}

	renderItem({ item }) {
		return (
			<>
				<Card
					key={item.id}
					title={item.title}
					subtitle={item.subtitle}
					content={(
						<>
							{item.content.map((r) => <Text>{r}</Text>)}
						</>
					)}
					left={null}
					actions={item.actions.map((r) => {
						return {
							title: r.title,
							onPress: async () => {
								if (r.type === 'link') {
									return {
										...r.button,
										url: r.url
									};
								}

								return {
									...r.button,
									onPress: async () => {
										await this.executeAction(r);
									}
								};
							}
						}
					})}
				/>
			</>
		);
	}

	private renderItems() {
		let { data } = this.state;

		return (
			<>
				<View>

				</View>

				<FlatList
					keyboardShouldPersistTaps={'always'}
					showsVerticalScrollIndicator={false}
					data={data}
					renderItem={this.renderItem.bind(this)}
					keyExtractor={(item: any, i) => item.id}
					style={{ width: '100%' }}
				/>
			</>
		);
	}

	render() {
		const { title, search } = this.state;

		return (
			<Screen appBar={{
				hasBack: true,
				onBackPress: this.goBack.bind(this),
				title
			}}>

				{search !== false ? (<Searchbar
					placeholder="Buscar..."
					onChangeText={async (query) => {
						await this.setState({ searchText: query });

						if (query.length === 0) {
							await this.clearSearch();
							clearTimeout(this.searchDefer);
							return;
						}

						// Run Search

						if (this.searchDefer) {
							clearTimeout(this.searchDefer);
						}

						this.searchDefer = setTimeout(async () => {
							await this.runSearch();
						}, 100);
					}}
					value={this.state.searchText}
				/>) : null}

				<View style={styles.container}>
					{this.renderItems()}
				</View>

				<Loading ref={(ref) => this.loading = ref} />
			</Screen>
		);
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: '#fff',
		alignItems: 'center',
		// justifyContent: 'center',
		padding: 0,
	}
});
