import React, { Component } from 'react';
import { StyleSheet, View, FlatList } from 'react-native';

import Screen from './components/Screen';
import { Button, Text, Searchbar } from 'react-native-paper';

import AppBar from './components/AppBar';
import Notifications from './components/Notifications';

import Fuse from 'fuse.js';

import Card from './components/Card';
import Loading from './components/Loading';

import Requests from './helpers/Requests';
import User from './helpers/User';

import ClientSync from './sync/clients';
import PricelistSync from './sync/pricelists';
import HomeSync from './sync/home';

export default class ImpersonatorScreen extends Component<any, any> {
	private data: any[];
	private fuse: any;
	private searchDefer: any = null;

	private notifications: Notifications;

	state: any = {
		data: [],

		isImpersonated: false,
		profile: { name: '' }
	};

	loading: Loading;

	async componentDidMount() {
		let isImpersonated = await User.isImpersonated();

		if (isImpersonated) {
			let profile = await User.getProfile();

			this.setState({ isImpersonated: true, profile });
		} else {
			this.loadData();
		}
	}

	private async loadData() {
		this.loading.show();
		let data = await Requests.get('/users');

		// console.info(data);

		// Copy into data for seach
		this.data = [...data];

		this.fuse = new Fuse(this.data, {
			shouldSort: true,
			tokenize: true,
			threshold: 0.4,
			location: 0,
			distance: 100,
			maxPatternLength: 32,
			minMatchCharLength: 1,
			keys: ['nombre'],
		});

		this.setState({ data: this.data });

		this.loading.hide();
	}

	private goBack() {
		this.props.navigation.goBack();
	}

	private async runSearch() {
		let { searchText } = this.state,
			results = this.fuse.search(searchText);

		await this.setState({ data: results.map(r => r.item) });
	}

	private async clearSearch() {
		await this.setState({ data: this.data });
	}

	private async reSync() {
		await Promise.all([
			ClientSync.sync(true),
			PricelistSync.sync(true),
			HomeSync.sync(true)
		]);
	}

	private async impersonate(item) {
		this.notifications.dialog.show({
			title: 'Cambio de Usuario',
			text: `Está seguro que quiere utilizar el usuario de ${item.nombre}?`,
			actions: [
				{
					title: 'Si', onPress: async () => {
						this.notifications.dialog.hide();

						this.loading.show();

						await User.impersonate(item);

						await this.reSync();

						this.loading.hide();

						this.goBack();
					}
				},
				{
					title: 'No',
					onPress: () => this.notifications.dialog.hide()
				}
			]
		});
	}

	renderItem({ item }) {
		let { id, nombre } = item;

		return (
			<>
				<Card
					key={id}
					title={nombre}
					content={(
						<>
						</>
					)}
					left={null}
					actions={[{
						title: 'Cambiar al usuario',
						onPress: async () => {
							console.info('Details: ', item);

							this.impersonate(item);
						}
					}]}
				/>
			</>
		);
	}

	private renderItems() {
		let { data } = this.state;

		return (
			<>
				<View>

				</View>

				<FlatList
					keyboardShouldPersistTaps={'always'}
					showsVerticalScrollIndicator={false}
					data={data}
					renderItem={this.renderItem.bind(this)}
					keyExtractor={(item: any, i) => item.id}
					style={{ width: '100%' }}
				/>
			</>
		);
	}

	private renderRemoveImpersonation() {
		let { profile } = this.state;

		return (
			<View
				style={{ width: '100%', padding: 15 }}>
				<View style={{ marginBottom: 15 }}>
					<Text>Actualmente se encuentra utilizando el usuario: </Text>

					<Text style={{ width: '100%', textAlign: 'center', marginTop: 15, fontSize: 20 }}>{profile.name}</Text>
				</View>

				<Button
					mode="outlined"
					style={{ width: '100%', marginTop: 20 }}
					onPress={async () => {
						this.loading.show();

						await User.deimpersonate();

						await this.reSync();

						this.loading.hide();

						this.goBack();
					}}>Remover usuario</Button>
			</View>
		);
	}

	render() {
		let { isImpersonated } = this.state;

		return (
			<Screen appBar={{
				hasBack: true,
				onBackPress: this.goBack.bind(this),
				title: "Cambio de Usuario"
			}}>

				{!!isImpersonated && (
					<>{this.renderRemoveImpersonation()}</>
				)}

				{!isImpersonated && (
					<>
						<Searchbar
							placeholder="Buscar..."
							onChangeText={async (query) => {
								await this.setState({ searchText: query });

								if (query.length === 0) {
									await this.clearSearch();
									clearTimeout(this.searchDefer);
									return;
								}

								// Run Search

								if (this.searchDefer) {
									clearTimeout(this.searchDefer);
								}

								this.searchDefer = setTimeout(async () => {
									await this.runSearch();
								}, 100);
							}}
							value={this.state.searchText}
						/>

						<View style={styles.container}>
							{this.renderItems()}
						</View>
					</>
				)}

				<Loading ref={(ref) => this.loading = ref} />

				<Notifications ref={ref => this.notifications = ref} />
			</Screen>
		);
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: '#fff',
		alignItems: 'center',
		padding: 0,
	},
});
