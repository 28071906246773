import * as React from 'react';

import { StyleSheet, View, Image } from 'react-native';

import Modal from "../Modal";

import { Button, Text } from 'react-native-paper';

import Title from '../Title';

export default class ProductDialog extends React.Component<any, any> {

	state: any = {
		isModalVisible: false,
		modalData: false
	};

	public show(data) {
		this.setState({
			isModalVisible: true,
			modalData: data
		});
	}

	public hide() {
		this.setState({
			isModalVisible: false
		});
	}

	public render() {
		let { modalData } = this.state;

		if (!modalData) return null;

		/*
		"AFIP_EQ_V": 1,
    "COD_BARRA": "7798060852235",
    "FECHA_MODI": "2019-05-04T11:09:27.290Z",
    "PRECIO": 2200,
    "Producto": "SUERO DE MANTECA EN POLVO ",
    "cod_barra": "7798060852235",
    "cod_caja": "",
    "id": 5973,
    "id_lista": 28,
    "id_producto": "2011100003",
    "peso": 25,
    "precio_unidad": 2200,
    "precioxkg": 88,
    "stock": 0,
		*/

		let { id_producto, Producto, vida_util, COD_BARRA, cod_caja, peso, AFIP_EQ_V, tam_lote } = modalData;

		return (
			<Modal
				isVisible={this.state.isModalVisible}
				onBackButtonPress={this.hide.bind(this)}
				onBackdropPress={this.hide.bind(this)}
				style={{ margin: 10, padding: 0 }}>
				<View style={{ flexDirection: 'column', backgroundColor: 'white', borderRadius: 10 }}>
					<Image
						style={styles.imageContain}
						source={{ uri: `https://pedidos.elcor.com.ar/images/${id_producto}.png` }}
						defaultSource={require('../../images/logo.png')} />

					<View
						style={{ width: '100%', padding: 15, borderBottomColor: '#EAEAEA', borderBottomWidth: 1 }}>
						<Title>{Producto}</Title>
						<Text>Vida útil: {vida_util} días</Text>
						<Text>EAN: {COD_BARRA}</Text>
						<Text>DUN: {cod_caja}</Text>
						<Text>Cont. Neto: {parseFloat(peso).toFixed(2)}</Text>
						<Text>Bulto: {AFIP_EQ_V} Unidades</Text>
						<Text>Cajas x Pallet: {tam_lote}</Text>
					</View>

					{/* Show orders from client */}

					<View
						style={{ width: '100%', marginTop: 0 }}>
						<Button
							style={{ padding: 15 }}
							onPress={this.hide.bind(this)}>Cerrar</Button>
					</View>
				</View>
			</Modal>
		);
	}
}

const styles = StyleSheet.create({
	imageContain: {
		width: '80%',
		height: 200,
		margin: 15,
		resizeMode: 'contain',
		alignSelf: 'center'
	}
});
