import * as React from 'react';

import Snackbar from '../Snackbar';
import Dialog from '../Dialog';

export default class Notifications extends React.Component<any, any> {

	public snackbar: Snackbar;
	public dialog: Dialog;

  public render() {
	return (
	  <>
		  {/* Snackbar */}
		  <Snackbar ref={(ref) => this.snackbar = ref} />
		  {/* Dialog */}
		  <Dialog ref={(ref) => this.dialog = ref} />
	  </>
	);
  }
}
