import React, { Component } from 'react';
import { StyleSheet, View, FlatList, ScrollView, Dimensions } from 'react-native';

import Modal from "./components/Modal";

import { Button, Searchbar, Text, Colors } from 'react-native-paper';

import MultiSelect from 'react-native-multiple-select';

import Screen from './components/Screen';

import Fuse from 'fuse.js';

import Title from './components/Title';

import Clients from './sync/clients';
import Requests from './helpers/Requests';
import Card from './components/Card';
import Loading from './components/Loading';
import Notifications from './components/Notifications';

/*const AnimatedBox = posed.View({
	useNativeDriver: true,
	visible: { opacity: 1, atStart: { display: 'flex' } },
	hidden: { opacity: 0, height: 0, atEnd: { display: 'none' } }
});*/

export default class OrdersScreen extends Component<any, any> {
	private data: any[];
	private fuse: any;
	private searchDefer: any = null;

	private page = 0;
	private canLoadMore: boolean = true;

	state: any = {
		orders: [],
		filters: [],
		clients: [],

		order: false,
		isOrderVisible: false,

		isFiltersVisible: false,

		searchText: '',

		selectedFilters: {}
	};

	loading: Loading;
	notifications: Notifications;

	async componentDidMount() {
		await Promise.all([this.loadData(), this.loadFilters(), new Promise(async () => {
			let clients = await Clients.get();

			this.setState({ clients });
		})]);
		// this.loadData();
	}

	private async loadFilters() {
		try {
			let filters = await Requests.get('/order/filters');

			console.info(filters);

			this.setState({ filters });
		} catch (e) { }
	}

	private async loadData() {
		this.loading.show();

		let data = await Requests.post('/orders', {
			// page: this.page
			...this.state.selectedFilters
		});

		// Copy into data for seach
		this.data = [...data];

		this.fuse = new Fuse(this.data, {
			shouldSort: true,
			tokenize: true,
			threshold: 0.4,
			location: 0,
			distance: 100,
			maxPatternLength: 32,
			minMatchCharLength: 1,
			keys: ['NRO_PEDIDO', 'CLIENTE'],
		});

		this.setState({ orders: this.data });

		console.info(this.data);

		this.loading.hide();
	}

	private goBack() {
		this.props.navigation.goBack();
	}

	private async getOrder(orderID) {
		this.loading.show();
		let order = await Requests.get(`/order/${orderID}`, {});

		console.info(order);
		this.setState({
			order,
			isOrderVisible: true
		});

		this.loading.hide();
	}

	private showWarning(data) {
		return new Promise((resolve, reject) => {
			this.notifications.dialog.show({
				...data,
				actions: [
					{
						title: 'Si', onPress: async () => {
							this.notifications.dialog.hide();

							resolve();
						}
					},
					{
						title: 'No',
						onPress: () => {
							this.notifications.dialog.hide();

							reject();
						}
					}
				]
			});
		});
	}

	private async executeAction(action) {
		try {
			if (action.warning !== false) {
				await this.showWarning(action.warning);
			}

			if (action.type == 'HTTP') {
				try {
					this.loading.show();

					let res = await Requests.get(action.url, {});

					await this.notifications.dialog.show({
						title: 'Acción ejecutada',
						text: res.message ? res.message : 'La acción fue ejecutada con éxito.'
					});

					this.loadData();
				} catch (e) {
					await this.notifications.dialog.show({
						title: 'Error',
						text: e.error ? e.error : 'Se produjo un error al ejecutar la acción.'
					});
				}

				this.loading.hide();
			}
		} catch (e) { }
	}

	private async runSearch() {
		let { searchText } = this.state,
			results = this.fuse.search(searchText);

		await this.setState({ data: results.map(r => r.item) });
	}

	private async clearSearch() {
		await this.setState({ orders: this.data });
	}

	private async resetAdvancedSearch() {
		await this.setState({ selectedFilters: {} });

		this.closeAdvancedSearch();
		this.loadData();
	}

	private toggleAdvancedSearch() {
		let { isFiltersVisible } = this.state;

		this.setState({ isFiltersVisible: !isFiltersVisible });
	}

	private closeAdvancedSearch() {
		this.setState({ isFiltersVisible: false });
	}

	private getStatusColor(status) {
		if (status === 1) {
			return Colors.orange400;
		} else if (status === 2) {
			return Colors.green400;
		} else if (status === 3) {
			return Colors.red200;
		} else {
			return Colors.red600;
		}
	}

	private parseDate(od) {
		let orderDate = new Date(od);

		// Convert into ARG TimeZone
		orderDate.setHours(orderDate.getHours() + 3);

		return `${orderDate.toLocaleDateString('es')} - ${orderDate.toLocaleTimeString('es')}`;
	}

	renderItem({ item }) {
		return (
			<>
				<Card
					key={item.uid}
					title={`${(item.CHECKED == 1 ? '✔ ' : '')}${item.CLIENTE}`}
					subtitle={<Text style={{ color: this.getStatusColor(item.ESTADO), fontWeight: 'bold' }}>{item.DESC_ESTADO}</Text>}
					content={(
						<>
							<Text>Nro Pedido: {item.NRO_PEDIDO}</Text>
							<Text>Factura: {item.N_COMP}</Text>
							<Text>Fecha: {this.parseDate(item.FECHA_PEDIDO)}</Text>
						</>
					)}
					right={(
						<Text style={{ paddingLeft: 15, textAlign: 'right' }}>$ {item.TOTAL}</Text>
					)}
					left={null}
					actions={[{
						title: 'Ver Pedido',
						onPress: async () => {
							console.info('Ver Pedido...', item.NRO_PEDIDO);

							await this.getOrder(item.NRO_PEDIDO);
						}
					}, ...item.actions.map((r) => {
						if (r.type === 'link') {
							return {
								...r.button,
								url: r.url
							};
						}

						return {
							...r.button,
							onPress: async () => {
								await this.executeAction(r);
							}
						};
					})]}
				/>
			</>
		);
	}

	private renderItems() {
		let { orders } = this.state;

		// Show Cards Here
		/*
		<Card
			title=
			content=Text|Component
			actions=[]
		*/

		return (
			<>
				{this.renderFilters()}

				<FlatList
					keyboardShouldPersistTaps={'always'}
					showsVerticalScrollIndicator={false}
					data={orders}
					renderItem={this.renderItem.bind(this)}
					keyExtractor={(item: any, i) => {
						return item.uid;
					}}
					style={{ width: '100%' }}
				/>
			</>
		);
	}

	private renderFilters() {
		let { isFiltersVisible, filters, clients, selectedFilters } = this.state;

		if (!filters || filters.length === 0) {
			return null;
		}

		return (
			<View style={{ display: isFiltersVisible ? 'flex' : 'none', flexDirection: 'column', width: '100%', padding: 15, backgroundColor: '#FAFAFA' }}>
				{filters.map(r => {
					let values = r.values;

					if (typeof (r.values) === 'string') {
						values = [];
					}

					if (typeof (r.values) === 'string' && r.values === 'cache.clients' && clients && clients.length > 0) {
						values = clients.map((c) => {
							return {
								label: c.NOMBRE,
								value: c.ID
							};
						});
					}

					let selectedItems = [];
					if (selectedFilters[r.filter]) {
						selectedItems = [selectedFilters[r.filter]];
					}

					return (
						<View style={{ width: '100%', marginBottom: 15 }}>
							<MultiSelect
								hideTags
								items={values.map((s) => { return { id: s.value, name: s.label } })}
								uniqueKey="id"
								onSelectedItemsChange={(selectedItems) => {
									let val = selectedItems[0];

									this.setState({
										selectedFilters: {
											...selectedFilters,
											[r.filter]: val
										}
									});
								}}
								selectedItems={selectedItems}
								selectText={`Seleccionar ${r.label}`}
								searchInputPlaceholderText={`Buscar ${r.label}...`}
								onChangeInput={(text) => console.log(text)}
								tagRemoveIconColor="#CCC"
								tagBorderColor="#CCC"
								tagTextColor="#CCC"
								selectedItemTextColor="#CCC"
								selectedItemIconColor="#CCC"
								itemTextColor="#000"
								displayKey="name"
								searchInputStyle={{ color: '#CCC' }}
								styleRowList={{ paddingTop: 5, paddingBottom: 5 }}
								submitButtonColor="#CCC"
								submitButtonText="Buscar"
								single={true}
							/>
						</View>
					);
				})}

				<Button
					mode="outlined"
					style={{ width: '100%', marginTop: 10 }}
					onPress={() => {
						this.closeAdvancedSearch();

						this.loadData();
					}}>Buscar</Button>

				<Button
					mode="outlined"
					color="red"
					style={{ width: '100%', marginTop: 10 }}
					onPress={() => {
						this.resetAdvancedSearch();
					}}>Reiniciar</Button>
			</View>
		);
	}

	renderOrderModal() {
		let { order, isOrderVisible } = this.state;

		if (isOrderVisible !== true) return null;
		if (!order) return null;

		try {
			let { CLIENTE, DESCUENTO, NRO_PEDIDO, N_COMP, ESTADO, DESC_ESTADO, TOTAL, FECHA_PEDIDO, DIRECCION_ENTREGA, CHECKED, items } = order;

			return (
				<Modal
					isVisible={this.state.isOrderVisible}
					onBackButtonPress={() => this.setState({ isOrderVisible: false })}
					onBackdropPress={() => this.setState({ isOrderVisible: false })}
					style={{ margin: 10, padding: 0 }}>
					<View style={{ flexDirection: 'column', backgroundColor: 'white', borderRadius: 10 }}>
						<ScrollView
							style={{ width: '100%', maxHeight: Dimensions.get("window").height / 1.5 }}>

							<View
								style={{ width: '100%', padding: 15, borderBottomColor: '#EAEAEA', borderBottomWidth: 1 }}>
								<Title>{CHECKED == 1 ? '✔ ' : ''}{CLIENTE}</Title>
								<Text>Fecha: {this.parseDate(FECHA_PEDIDO)}</Text>
								<Text>Sucursal: {DIRECCION_ENTREGA}</Text>
								<Text>Nro Pedido: {NRO_PEDIDO}</Text>
								<Text>Comprobante: {N_COMP}</Text>
								<Text style={{ color: this.getStatusColor(ESTADO), fontWeight: 'bold' }}>Estado: {DESC_ESTADO}</Text>
								<Text>Descuento: {DESCUENTO}</Text>
								<Text style={{ fontWeight: 'bold' }}>Total: $ {TOTAL || 0}</Text>
							</View>

							{items.map((r) => {
								return (
									<Card
										key={r.ID_PRODUCTO}
										title={r.DESCRIPCION}
										content={
											<>
												<View style={{ flexDirection: 'column' }}>
													<Text style={{ flex: 1 }}>Cantidad {r.CANTIDAD}</Text>
													<Text style={{ flex: 1 }}>Precio por Unidad {parseFloat(r.PRECIO).toFixed(2)}</Text>
													<Text style={{ flex: 1 }}>Precio Total {parseFloat(r.TOTAL).toFixed(2)}</Text>
												</View>
											</>
										}
									/>
								)
							})}
						</ScrollView>

						<View
							style={{ width: '100%', marginTop: 0 }}>
							<Button
								style={{ padding: 15 }}
								onPress={() => this.setState({ isOrderVisible: false })}>Cerrar</Button>
						</View>
					</View>
				</Modal>
			);

		} catch (e) {
			console.error(e);
		}
	}

	render() {
		return (
			<Screen appBar={{
				hasBack: true,
				onBackPress: this.goBack.bind(this),
				title: "Pedidos Emitidos",
				actions:
					[{
						icon: "search",
						onPress: () => {
							this.toggleAdvancedSearch();
						}
					}]
			}}>

				<Searchbar
					placeholder="Buscar..."
					onChangeText={async (query) => {
						await this.setState({ searchText: query });

						if (query.length === 0) {
							await this.clearSearch();
							clearTimeout(this.searchDefer);
							return;
						}

						// Run Search

						if (this.searchDefer) {
							clearTimeout(this.searchDefer);
						}

						this.searchDefer = setTimeout(async () => {
							await this.runSearch();
						}, 100);
					}}
					value={this.state.searchText}
				/>
				<View>

				</View>

				<View style={styles.container}>
					{this.renderItems()}
				</View>

				{this.renderOrderModal()}

				<Loading ref={(ref) => this.loading = ref} />
				<Notifications ref={ref => this.notifications = ref} />
			</Screen>
		);
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: '#fff',
		alignItems: 'center',
		// justifyContent: 'center',
		padding: 0,
	},
});
