import {
	AsyncStorage
} from 'react-native';

const user_storage_key = 'user';

import Base64 from './Base64';

// User Class
class UserCls {
	private listeners: any[] = [];

	async isLoggedIn() {
		let user = await this.get();

		return user && user.token;
	}

	async impersonate(impUser) {
		let user = await this.get();

		let data = {
			...user,
			impersonated: impUser
		}

		return this.set(data);
	}

	async deimpersonate() {
		let user = await this.get();

		delete user.impersonated;

		return this.set(user);
	}

	async isImpersonated() {
		let user = await this.get();

		return !!user.impersonated;
	}

	async getHeaders() {
		let user = await this.get(),
			headers = {};

		if (!!user && user.token) {
			headers['Authorization'] = `Bearer ${user.token}`;

			if (!!user.impersonated) {
				headers['x-impersonate'] = Base64.to( JSON.stringify(user.impersonated) );
			}
		}

		return headers;
	}

	async set(data) {
		try {
			await AsyncStorage.setItem(user_storage_key, JSON.stringify(data));

			this.emit('set');

			return this.get();
		} catch (e) {
			return false;
		}
	}

	async get() {
		try {
			let data = await AsyncStorage.getItem(user_storage_key);

			if (data) {
				return JSON.parse(data);
			}
		} catch (e) {
		}

		return null;
	}

	async getProfile(): Promise<{ id: string, name: string }> {
		let user = await this.get();

		if (!!user) {
			if (!!user.impersonated) {
				return { id: user.impersonated.id, name: user.impersonated.nombre };
			}

			return { id: user.id, name: user.nombre };
		}

		return { id: null, name: '' };
	}

	async remove() {
		await AsyncStorage.removeItem(user_storage_key);

		this.emit('removed');

		return;
	}

	public listen(listener) {
		this.listeners.push(listener);
	}

	private emit(event) {
		for (let listener of this.listeners) {
			try {
				listener(event);
			} catch (e) {
				console.info('Error emiting listener', e);
			}
		}
	}
}

export default new UserCls();
