import React, { Component } from 'react';
import { AsyncStorage, StyleSheet, ScrollView, View, Image } from 'react-native';
import { NavigationEvents } from 'react-navigation';

import Screen from './components/Screen';

import { List, Text } from 'react-native-paper';

import AppList from './components/List';

import { BottomNavigation } from 'material-bread';

import Constants from 'expo-constants';

import AppBar from './components/AppBar';

import EmptyBox from './components/EmptyBox';

import Notifications from './components/Notifications';

import User from './helpers/User';
import Requests from './helpers/Requests';
import OrderSync from './sync/OrderSync';
import HomeSync from './sync/home';

export default class HomeScreen extends Component<any, any> {

	private notifications: Notifications;

	state: any = {
		name: '',

		isAdmin: false,

		homeData: [],

		orders: [],
		notifications: [],

		selectedTab: 0,
	};

	async componentDidMount() {
		let profile = await User.getProfile(),
			user = await User.get();

		this.setState({
			name: profile.name,
			isAdmin: user.tipo_usuario === 3
		});

		this.loadHome();
		this.loadNotifications();

		// Listen for changes
		OrderSync.listen(async (event) => {
			// TODO: Only status READY
			let orders = await OrderSync.all([OrderSync.states.READY]);

			this.setState({
				orders
			});
		});
	}

	async loadHome() {
		let homeData = await HomeSync.get();

		this.setState({ homeData });
	}

	async loadNotifications() {
		let data = await Requests.get('/notification', {});
		// console.log("TCL: HomeScreen -> loadNotifications -> data", data)

		this.setState({ notifications: data ?? [] });
	}

	async onDidFocus(payload) {
		let profile = await User.getProfile(),
			orders = await OrderSync.all([OrderSync.states.READY]),
			homeData = await HomeSync.get();

		this.setState({
			name: profile.name,
			orders,
			homeData
		});
	}

	/*private goToNewOrder() {
		this.props.navigation.push('NewOrder');
	}

	private goToOrders() {
		this.props.navigation.push('Orders');
	}

	private goToClients() {
		this.props.navigation.push('Clients');
	}

	private goToProducts() {
		this.props.navigation.push('Products');
	}

	private goToPricelists() {
		this.props.navigation.push('Pricelists');
	}

	private goToDispatches() {
		this.props.navigation.push('Dispatches');
	}

	private goToDispatcher() {
		this.props.navigation.push('Dispatcher');
	}*/

	private goToOrdersSync() {
		this.props.navigation.push('OrdersSync');
	}

	private goToImpersonator() {
		this.props.navigation.push('Impersonator');
	}

	private async logout() {
		this.notifications.dialog.show({
			title: 'Cerrar Sesión',
			text: 'Está seguro de cerrar sesión? Esta acción no puede cancelarse',
			actions: [
				{
					title: 'Si', onPress: async () => {
						await User.remove();
						await AsyncStorage.clear();

						this.notifications.dialog.hide();

						this.props.navigation.replace('Login');
					}
				},
				{
					title: 'No',
					onPress: () => this.notifications.dialog.hide()
				}
			]
		});
	}

	render() {
		let { name, isAdmin, orders, homeData, notifications, selectedTab } = this.state;

		return (
			<Screen appBar={{
				title: "Bienvenido",
				subtitle: name
			}} onDidFocus={this.onDidFocus.bind(this)}>

				<ScrollView style={styles.container}>
					{/*<Image
						style={{ height: 50, resizeMode: 'contain', marginBottom: 40, marginTop: 20, alignSelf: 'center' }}
					source={require('./images/logo.png')} />*/}

					{/*<Text>Acciones</Text>*/}

					{selectedTab === 0 && (
						<>
							<AppList
								data={homeData.map((r) => {
									return {
										...r,
										onPress: async () => {
											let { action } = r;

											if (!action) return;

											if (action.type === 'navigation') {
												this.props.navigation.push(action.path, action.params);
											}
										}
									}
								})} />

							{/*<List.Section style={styles.section}>
								<List.Subheader>Pedidos</List.Subheader>
								<List.Item
									onPress={this.goToNewOrder.bind(this)}
									title="Nuevo"
									description={"Cree un nuevo Pedido"}
									left={() => <List.Icon color="#000" icon="plus" />}
								/>

								<List.Item
									onPress={this.goToOrders.bind(this)}
									title="Ver Pedidos"
									description={"Administre sus pedidos creados, cancelelos..."}
									left={() => <List.Icon color="#000" icon="format-list-bulleted" />}
								/>
							</List.Section>

							<List.Section style={styles.section}>
								<List.Subheader>Clientes</List.Subheader>
								<List.Item
									onPress={this.goToClients.bind(this)}
									title="Listado de Clientes"
									description={"Vea sus clientes y detalles"}
									left={() => <List.Icon color="#000" icon="account" />}
								/>
							</List.Section>

							<List.Section style={styles.section}>
								<List.Subheader>Productos</List.Subheader>
								<List.Item
									onPress={this.goToProducts.bind(this)}
									title="Listado de Productos"
									description={"Vea los productos y detalles"}
									left={() => <List.Icon color="#000" icon="cart" />}
								/>
								<List.Item
									onPress={this.goToPricelists.bind(this)}
									title="Listas de Precios"
									description={"Vea y descargue las Listas de Precios disponibles"}
									left={() => <List.Icon color="#000" icon="file-download" />}
								/>
							</List.Section>

							<List.Section style={styles.section}>
								<List.Subheader>Remitos</List.Subheader>
								<List.Item
									onPress={this.goToDispatches.bind(this)}
									title="Listado de Remitos"
									description={"Vea los remitos y detalles"}
									left={() => <List.Icon color="#000" icon="cart" />}
								/>
								<List.Item
									onPress={this.goToDispatcher.bind(this)}
									title="Conformación de Remitos"
									description={"Desde aquí podrá conformar nuevos Remitos"}
									left={() => <List.Icon color="#000" icon="file-download" />}
								/>
					</List.Section>*/}
						</>
					)}

					{selectedTab === 1 && (
						<>
							{notifications.length === 0 ? (
								<>
									<EmptyBox icon="notifications-none" text="No hay notificaciones!" />
								</>
							) : (
									<AppList
										data={(notifications ?? []).map(r => {
											return {
												icon: 'notifications',
												title: r.TITULO,
												value: r.MENSAJE
											};
										})} />
								)}
						</>
					)}

					{selectedTab === 2 && (
						<>
							{/* Logout and others ( Test push ) */}
							{!!isAdmin && (
								<AppList
									style={{ marginBottom: 8 }}
									title="Administración"
									data={[{
										title: 'Cambiar Usuario',
										value: 'Desde aquí como administrador podrá tomar el lugar de otro usuario en la aplicación.',
										icon: "group",
										onPress: this.goToImpersonator.bind(this)
									}]} />
							)}

							<AppList
								style={{ marginBottom: 8 }}
								title="Sincronización"
								data={[{
									title: 'Órdenes para sincronizar',
									value: `Pendientes: ${orders.length}`,
									icon: 'loop',
									onPress: this.goToOrdersSync.bind(this)
								}]} />

							<AppList
								title="Usuario"
								data={[{
									title: 'Probar Notificaciones',
									value: 'Desde aquí podrá probar si el proceso de registro Push fue realizado correctamente',
									icon: 'notifications',
									onPress: async () => {
										await Requests.get('/user/push/test');
									}
								}, {
									title: 'Cerrar Sesión',
									icon: "power-settings-new",
									onPress: this.logout.bind(this)
								}]} />

							<Text style={{ marginTop: 10, marginBottom: 30, width: '100%', textAlign: 'center', paddingTop: 10, paddingBottom: 15 }}>Versión: {Constants.manifest.version}</Text>
						</>
					)}

					<View style={{ width: '100%', height: 40 }} />
				</ScrollView>

				<BottomNavigation
					style={{ width: '100%' }}
					backgroundColor={'white'}
					value={this.state.selectedTab}
					handleChange={(value) => {
						this.setState({ selectedTab: value })
					}}
					actionItems={[
						{ icon: 'home', label: 'Inicio' },
						{ icon: 'notifications', label: 'Notificaciones' },
						{ icon: 'settings', label: 'Config.' },
					]}
				/>

				<Notifications ref={ref => this.notifications = ref} />
			</Screen>
		);
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: '#EAEAEA',
		paddingTop: 10,
		paddingBottom: 60
	},

	section: {
		width: '100%', backgroundColor: 'white', marginTop: 3, marginBottom: 3
	}
});
