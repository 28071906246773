import React, { Component } from 'react';
import { View, Platform, Dimensions } from 'react-native';

import Fuse from 'fuse.js';

import { Searchfield, Button, Colors } from 'material-bread';

import Modal from "../Modal";
import List from '../List';

export default class SearchDialog extends Component<any, any> {

	private data: any[] = [];
	private fuse: any;

	private searchDefer: any = null;

	private template: (r: any) => any = (r) => { return { title: '', value: '' } };

	state = {
		isVisible: false,
		search: '',
		data: [],

		cancelText: ''
	};

	private async runSearch() {
		let { search } = this.state,
			results = this.fuse.search(search);

		await this.setState({ data: results.map(r => r.item) });
	}

	private async clearSearch() {
		await this.setState({ search: '', data: this.data });
	}

	public show(options: { data: any[], template: (r: any) => any, search: { keys: string[] }, cancelText?: string }) {
		this.data = [...options.data];
		this.template = options.template;

		let searchOptions = {
			shouldSort: true,
			tokenize: true,
			threshold: 0.4,
			location: 0,
			distance: 100,
			maxPatternLength: 32,
			minMatchCharLength: 1,
			keys: [],
			...options.search
		}

		this.fuse = new Fuse(this.data, searchOptions);

		this.setState({
			isVisible: true,
			data: this.data,
			cancelText: options.cancelText ?? 'Cancelar'
		});
	}

	public hide() {
		this.setState({
			isVisible: false
		});
	}

	public render() {
		let { isVisible, data, cancelText } = this.state;

		const { height } = Dimensions.get('window');

		return (
			<Modal
				isVisible={isVisible}
				onBackButtonPress={() => this.setState({ isLoadingVisible: false })}
				onBackdropPress={() => this.setState({ isLoadingVisible: false })}
				style={{ marginLeft: 10, marginRight: 10, alignItems: 'flex-end' }}
			>
				<View style={{
					position: 'absolute',
					bottom: Platform.OS === 'web' ? -55 : -25,
					borderTopLeftRadius: 10,
					borderTopRightRadius: 10,
					backgroundColor: 'white',
					width: '100%',
					padding: 0,
					paddingBottom: 4,
					flexDirection: 'column',
					display: 'flex',
					maxHeight: height * 0.5
				}}>
					<Searchfield
						color={Colors.grey[300]}
						closeIconProps={{ color: 'black ' }}
						searchIconProps={{ color: 'black' }}
						textStyle={{ color: 'black' }}
						style={{ color: 'black', margin: 8 }}
						placeholder={" "}
						value={this.state.search}
						onCloseIcon={() => {
							console.info('Clear Search');
							this.clearSearch();
						}}
						onChangeText={async (query) => {
							await this.setState({ search: query });
	
							if (query.length === 0) {
								await this.clearSearch();
								clearTimeout(this.searchDefer);
								return;
							}
	
							// Run Search
	
							if (this.searchDefer) {
								clearTimeout(this.searchDefer);
							}
	
							this.searchDefer = setTimeout(async () => {
								await this.runSearch();
							}, 100);
						}}
					/>

					{/* List */}
					<List style={{ marginTop: 8 }} data={data.map(this.template)} />

					<View
						style={{ width: '100%', padding: 8, paddingBottom: Platform.OS === "ios" ? 18 : 8 }}>
						<Button
							textColor="black"
							text={cancelText}
							type="text"
							fullWidth
							onPress={this.hide.bind(this)} />
					</View>

				</View>
			</Modal>
		);
	}
}
