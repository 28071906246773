import SyncClient from './SyncClient';

class Pricelists extends SyncClient {
	public url: string = `/pricelists`;
	public requestType: string = 'GET';
	public storageKey: string = 'pricelists';
	public maxDuration: number = (10 * (60 * 1000));
	public syncInterval: number = 0.5 * (60 * 1000);
}

export default new Pricelists('Pricelists');