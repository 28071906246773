import React, { Component } from 'react';
import { StyleSheet, View, FlatList, ScrollView, Dimensions } from 'react-native';

import Screen from './components/Screen';
import Modal from "./components/Modal";

import { Button, Searchbar, Text, Colors } from 'react-native-paper';

import Fuse from 'fuse.js';

import Title from './components/Title';
import AppBar from './components/AppBar';

import Requests from './helpers/Requests';
import Card from './components/Card';
import Loading from './components/Loading';
import Notifications from './components/Notifications';

export default class DispatchesScreen extends Component<any, any> {
	private data: any[];
	private fuse: any;
	private searchDefer: any = null;

	state: any = {
		data: [],

		details: false,
		isDetailsVisible: false,

		searchText: '',

		selectedFilters: {}
	};

	loading: Loading;
	notifications: Notifications;

	async componentDidMount() {
		this.loadData();
	}

	private async loadData() {
		this.loading.show();

		let data = await Requests.get('/dispatch');

		// Copy into data for seach
		this.data = [...data];

		this.fuse = new Fuse(this.data, {
			shouldSort: true,
			tokenize: true,
			threshold: 0.4,
			location: 0,
			distance: 100,
			maxPatternLength: 32,
			minMatchCharLength: 1,
			keys: ['id', 'client.name'],
		});

		this.setState({ data: this.data });

		console.info(this.data);

		this.loading.hide();
	}

	private goBack() {
		this.props.navigation.goBack();
	}

	private async getDetails(item) {
		this.loading.show();

		this.setState({
			details: item,
			isDetailsVisible: true
		});

		this.loading.hide();
	}

	private async runSearch() {
		let { searchText } = this.state,
			results = this.fuse.search(searchText);

		await this.setState({ data: results.map(r => r.item) });
	}

	private async clearSearch() {
		await this.setState({ data: this.data });
	}

	private getStatusColor(status) {
		if (status === 1) {
			return Colors.orange400;
		} else if (status === 2) {
			return Colors.green400;
		} else if (status === 3) {
			return Colors.red200;
		} else {
			return Colors.red600;
		}
	}

	private parseDate(od) {
		let orderDate = new Date(od);

		// Convert into ARG TimeZone
		orderDate.setHours(orderDate.getHours() + 3);

		return `${orderDate.toLocaleDateString('es')} - ${orderDate.toLocaleTimeString('es')}`;
	}

	renderItem({ item }) {
		return (
			<>
				<Card
					key={item.id}
					title={`${item.id} - ${item.client.name}`}
					subtitle={<Text>{this.parseDate(item.date)}</Text>}
					content={(
						<>
							<Text>Dirección: {item.client.address}</Text>
							<Text>Cant. Productos: {item.products.length}</Text>
						</>
					)}
					right={null}
					left={null}
					actions={[{
						title: 'Ver Remito',
						onPress: async () => {
							console.info('Ver Data...', item);

							await this.getDetails(item);
						}
					}]}
				/>
			</>
		);
	}

	private renderItems() {
		let { data } = this.state;

		// Show Cards Here
		return (
			<>
				<FlatList
					keyboardShouldPersistTaps={'always'}
					showsVerticalScrollIndicator={false}
					data={data}
					renderItem={this.renderItem.bind(this)}
					keyExtractor={(item: any, i) => {
						return item.id;
					}}
					style={{ width: '100%' }}
				/>
			</>
		);
	}

	renderDetailsModal() {
		let { details, isDetailsVisible } = this.state;

		if (isDetailsVisible !== true) return null;
		if (!details) return null;

		try {
			let { id, date, state, description, observation, client, products } = details;

			return (
				<Modal
					isVisible={isDetailsVisible}
					onBackButtonPress={() => this.setState({ isDetailsVisible: false })}
					onBackdropPress={() => this.setState({ isDetailsVisible: false })}
					style={{ margin: 10, padding: 0 }}>
					<View style={{ flexDirection: 'column', backgroundColor: 'white', borderRadius: 10 }}>
						<ScrollView
							style={{ width: '100%', maxHeight: Dimensions.get("window").height / 1.5 }}>

							<View
								style={{ width: '100%', padding: 15, borderBottomColor: '#EAEAEA', borderBottomWidth: 1 }}>
								<Title>{id}</Title>
								<Text>Fecha: {this.parseDate(date)}</Text>
								<Text>Estado: {state}</Text>
								<Text>Descripción: {description}</Text>
								<Text>Observación: {observation != '' ? observation : 'Sin Observación'}</Text>
								<Text>Cliente: {client.name}</Text>
								<Text>Dirección: {client.address} {client.city}</Text>
								<Text>Entregado En: {client.deliveryAddress}</Text>
							</View>

							{products.map((r) => {
								return (
									<Card
										key={r.id}
										title={r.description}
										content={
											<>
												<View style={{ flexDirection: 'column' }}>
													<Text style={{ flex: 1 }}>Cantidad {r.quantity}</Text>
												</View>
											</>
										}
									/>
								)
							})}
						</ScrollView>

						<View
							style={{ width: '100%', marginTop: 0 }}>
							<Button
								style={{ padding: 15 }}
								onPress={() => this.setState({ isDetailsVisible: false })}>Cerrar</Button>
						</View>
					</View>
				</Modal>
			);

		} catch (e) {
			console.error(e);
		}
	}

	render() {
		return (
			<Screen appBar={{
				hasBack: true,
				onBackPress: this.goBack.bind(this),
				title: "Remitos"
			}}>

				<Searchbar
					placeholder="Buscar..."
					onChangeText={async (query) => {
						await this.setState({ searchText: query });

						if (query.length === 0) {
							await this.clearSearch();
							clearTimeout(this.searchDefer);
							return;
						}

						// Run Search

						if (this.searchDefer) {
							clearTimeout(this.searchDefer);
						}

						this.searchDefer = setTimeout(async () => {
							await this.runSearch();
						}, 100);
					}}
					value={this.state.searchText}
				/>
				<View>

				</View>

				<View style={styles.container}>
					{this.renderItems()}
				</View>

				{this.renderDetailsModal()}

				<Loading ref={(ref) => this.loading = ref} />
				<Notifications ref={ref => this.notifications = ref} />
			</Screen>
		);
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: '#fff',
		alignItems: 'center',
		padding: 0,
	},
});
