import React, { Component } from 'react';
import { StyleSheet, View, FlatList, TouchableOpacity, ScrollView, Image } from 'react-native';

import { Switch, Text, TextInput, Checkbox } from 'react-native-paper';

import { Button, Fab, Icon } from 'material-bread';

import MultiSelect from 'react-native-multiple-select';

import Screen from './components/Screen';

import Card from './components/Card';
import Title from './components/Title';

import Notifications from './components/Notifications';
import ProductDialog from './components/ProductDialog';

import Clients from './sync/clients';
import Pricelists from './sync/pricelists';

import OrderSync from './sync/OrderSync';

import Requests from './helpers/Requests';
import Loading from './components/Loading';

import SearchDialog from './components/SearchDialog';

enum OrderSteps {
	LOADING = 'LOADING',
	SELECT = 'SELECT',
	ITEMS = 'ITEMS',
	CREATE_ORDER = 'CREATE_ORDER',
	LOCKED = 'LOCKED'
};

/*
- Client
- Order
-- Plus button to add a new product. Opens a modal to search and select a product
-- The list shows the added products only and you can add a new onw
-- If a product already exists in the order allows to edit the quantity.
*/

// Multiples direcciones: "NOMBRE": "CARVI GUILLERMO", VARETTO
// HABILITA_ S: "NOMBRE": "ZAPATA MARIA RIOS MARCELO Y RIOS WALTER SH",
export default class NewOrderScreen extends Component<any, any> {
	calculateTimer: any;
	notifications: Notifications;
	productDialog: ProductDialog;
	searchDialog: SearchDialog;

	loading: Loading;

	state: any = {
		isNewOrder: false,
		isNewOrderVisible: false,

		isClientSelectorVisible: false,

		step: OrderSteps.LOADING,

		subtitle: false,

		clients: [],

		// Selected Options
		selectedClient: '',
		selectedPricelist: '',
		selectedAddress: '',

		client: false,
		pricelist: [],

		products: [],

		orderDiscount: '0',

		orderQuantity: 0,
		orderTotal: 0,

		ocText: '',
		lockedText: ''
	};

	multiSelect: MultiSelect;

	async componentDidMount() {
		try {
			this.loading.show();

			let userStatus = await this.validateUser();

			this.loading.hide();

			if (userStatus) {
				let clients = await Clients.get();

				this.setState({
					clients,
					step: OrderSteps.SELECT
				});
			}
		} catch (e) {

		}
	}

	private goBack() {
		this.props.navigation.goBack();
	}

	private async validateUser() {
		let data = await Requests.get('/order/validate', {});

		if (data.locked) {
			let lockedText = data.reason == 'CTACTE' ? 'CARGA DE PEDIDOS INHABILITADA YA QUE EXCEDIO EL LIMITE DE CREDITO.' : 'CARGA DE PEDIDOS INHABILITADA.';

			this.setState({
				step: OrderSteps.LOCKED,
				lockedText
			});

			return false;
		}

		return true;
	}

	private async selectClient(clientID) {
		let client = this.getCurrentClient(clientID);

		await this.setState({
			selectedClient: clientID,
			selectedAddress: client.addresses[0].ID_DIRECCION_ENTREGA,
			client,
			orderDiscount: `${client.DESCUENTO}`,
			isNewOrder: false,
			isNewOrderVisible: (client.HABILITA_ == 'S')
		});
	}

	private getCurrentClient(clientID) {
		let { clients } = this.state;

		return clients.find((r) => r.ID === clientID);
	}

	private async getClientPricelist(clientID, pricelistChangeChecked) {
		let client = this.getCurrentClient(clientID),
			pricelists = await Pricelists.get();

		let pricelist = client.LISTA_PRECIO;
		if (pricelistChangeChecked) {
			pricelist = client.LISTA_PRECIO_TILDE;
		}

		return {
			id: pricelist,
			data: [...pricelists[pricelist]].map((r) => {
				r.quantity = 0;
				return r;
			})
		};
	}

	private async startOrder() {
		try {
			if (this.state.selectedClient === '') {
				return this.notifications.snackbar.show({
					text: 'No hay cliente seleccionado'
				});
			}

			let { selectedClient, isNewOrder } = this.state;

			let client = this.getCurrentClient(selectedClient),
				pricelist = await this.getClientPricelist(selectedClient, isNewOrder);

			this.setState({
				subtitle: client.NOMBRE,
				selectedPricelist: pricelist.id,
				pricelist: pricelist.data,
				products: [],
				step: OrderSteps.ITEMS
			});
		} catch (e) {
			this.notifications.snackbar.show({
				text: 'Verifique la información ingresada.'
			});
		}
	}

	private async recalculate() {
		if (this.calculateTimer) clearTimeout(this.calculateTimer);

		this.calculateTimer = setTimeout(() => {
			let { products } = this.state;

			let orderQuantity: number = 0,
				orderTotal: number = 0;

			// Recalculate
			for (let prc of products) {
				let q = parseInt(prc.quantity != '' ? prc.quantity : 0);

				orderQuantity = orderQuantity + q;
				orderTotal = orderTotal + (parseFloat(prc.PRECIO) * q);
			}

			this.setState({
				orderQuantity,
				orderTotal
			});
		}, 10);
	}

	private async setQuantity(index, value) {
		let { products } = this.state,
			nVal = value == '' ? 0 : parseInt(value);

		if (!isNaN(nVal)) {
			products[index].quantity = nVal;

			this.setState({
				products
			});

			this.recalculate();
		}
	}

	private async showDetails() {
		let { products } = this.state;

		let items = products.filter(r => r.quantity > 0);

		if (items.length === 0) {
			await this.notifications.dialog.show({
				title: 'Revise',
				text: 'La orden no posee productos.'
			});

			return;
		}

		this.setState({
			step: OrderSteps.CREATE_ORDER
		});
	}

	private addItem(item: any) {
		let { products } = this.state;

		// check if is not already on the list, if exist add Q
		let idx = products.findIndex((r) => r.id_producto == item.id_producto);

		if (idx !== -1) {
			products[idx].quantity++
		} else {
			products.push({
				...item,
				quantity: 1
			});
		}

		this.setState({ products });
	}

	private removeItem(item: any) {
		let { products } = this.state;

		// check if is not already on the list, if exist add Q
		let idx = products.findIndex((r) => r.id_producto == item.id_producto);

		if (idx !== -1) {
			// Remove the element
			products.splice(idx, 1);

			this.setState({ products });
		}
	}

	private async createOrder() {
		let { products, selectedClient, selectedAddress, selectedPricelist, isNewOrder, orderDiscount, ocText } = this.state,
			client = this.getCurrentClient(selectedClient);

		let items = products.filter(r => r.quantity > 0);

		if (items.length === 0) {
			await this.notifications.dialog.show({
				title: 'Revise',
				text: 'La orden no posee productos.'
			});

			return;
		}

		this.loading.show('Enviando Orden...');

		let order = {
			// We must display it somewhere :P
			client: {
				id: client.ID,
				name: client.NOMBRE,
				address: client.addresses.find(r => r.ID_DIRECCION_ENTREGA == selectedAddress), // Complete Address Object
			},
			checked: isNewOrder,
			pricelist: selectedPricelist,
			discount: parseFloat(orderDiscount || '0') || 0,
			items,
			oc: ocText || ''
		};

		// client ID
		// checked
		// discount
		// console.info(order);
		let newOrder = await OrderSync.add(order, OrderSync.states.LOCKED);

		let res = await OrderSync.send(newOrder);

		this.loading.hide();

		if (res !== true) {
			await this.notifications.dialog.show({
				title: 'Nueva Orden',
				text: 'Orden generada. La orden quedará pendiente de sincronización.'
			});
		}

		this.goBack();
	}

	renderItem({ item, index }) {
		let stock = item.stock || 0;

		return (
			<>
				<Card
					key={`prd:${item.id_producto}`}
					title={item.Producto}
					titleStyle={{
						color: item.Producto.toLowerCase().indexOf('tonadita') === -1 ? 'red' : 'black'
					}}
					content={
						<>
							<View style={{ flexDirection: 'column', marginBottom: 15 }}>
								<Text>Unidad: $ {parseFloat(item.PRECIO).toFixed(2)} / Kg: $ {parseFloat(item.PRECIOXKG).toFixed(2)}</Text>
								<Text>Stock: {stock}</Text>
							</View>

							<TextInput
								value={`${item.quantity}`}
								autoCorrect={false}
								disableFullscreenUI
								keyboardType={'numeric'}
								onChangeText={(val) => {
									this.setQuantity(index, val);
								}}
							/>
						</>
					}
					actions={[{
						title: 'Ver Producto',
						onPress: async () => {
							this.productDialog.show(item);
						}
					}, {
						title: 'Eliminar Producto',
						onPress: async () => {
							this.removeItem(item);
						}
					}]}
				/>
			</>
		);
	}

	private renderSelect() {
		let { isNewOrder, isNewOrderVisible, clients, selectedClient, selectedAddress, client, orderDiscount } = this.state;

		// console.info(clients);

		return (
			<ScrollView style={{ width: '100%' }}>
				<View style={{ padding: 20, flex: 1, flexDirection: 'column', width: '100%' }}>
					<View style={{ flexDirection: 'column', width: '100%', marginTop: 8 }}>
						<MultiSelect
							hideTags
							items={clients.map((r) => { return { id: r.ID, name: r.NOMBRE } })}
							uniqueKey="id"
							ref={(component) => { this.multiSelect = component }}
							onSelectedItemsChange={(selectedItems) => {
								this.selectClient(selectedItems[0]);
							}}
							selectedItems={[selectedClient]}
							selectText="Seleccionar Cliente"
							searchInputPlaceholderText="Buscar Cliente..."
							onChangeInput={(text) => console.log(text)}
							tagRemoveIconColor="#CCC"
							tagBorderColor="#CCC"
							tagTextColor="#CCC"
							selectedItemTextColor="#CCC"
							selectedItemIconColor="#CCC"
							itemTextColor="#000"
							displayKey="name"
							searchInputStyle={{ color: '#CCC' }}
							styleRowList={{ paddingTop: 5, paddingBottom: 5 }}
							submitButtonColor="#CCC"
							submitButtonText="Buscar"
							single={true}
						/>
					</View>

					{isNewOrderVisible && (
						<View style={{ flexDirection: 'row', marginBottom: 20, marginTop: 8 }}>
							<Text style={{ padding: 8 }}>Nuevo Pedido</Text>
							<Switch value={isNewOrder} onValueChange={() => { this.setState({ isNewOrder: !isNewOrder }); }
							} />
						</View>
					)}

					<View style={{ width: '100%', flexDirection: 'column' }}>
						<TextInput
							style={{ width: '100%', marginBottom: 10, marginTop: 10 }}
							label="Descuento"
							onChangeText={(text) => this.setState({ orderDiscount: text })}
							value={orderDiscount}
							autoCorrect={false}
							disableFullscreenUI
							keyboardType={'decimal-pad'}
						/>
						<Text>Se requiere previa aprobación de la empresa.</Text>
					</View>

					{client && client.addresses && (
						<View style={{ width: '100%', flexDirection: 'column', marginTop: 20 }}>
							<Text style={{ marginBottom: 10, fontWeight: 'bold' }}>Seleccione una Dirección: </Text>

							{client.addresses.map((address) => {
								// ID_DIRECCION_ENTREGA
								return (
									<TouchableOpacity onPress={() => {
										this.setState({ selectedAddress: address.ID_DIRECCION_ENTREGA });
									}}>
										<View
											style={{ width: '100%', flexDirection: 'row' }}>
											<Text style={{ flexGrow: 1, alignSelf: 'center' }}>{address.COD_DIRECCION_ENTREGA}</Text>
											<Checkbox
												// value={address.COD_DIRECCION_ENTREGA}
												status={selectedAddress === address.ID_DIRECCION_ENTREGA ? 'checked' : 'unchecked'}
												onPress={() => {
													this.setState({ selectedAddress: address.ID_DIRECCION_ENTREGA });
												}}
											/>
										</View>
									</TouchableOpacity>
								);
							})}
						</View>
					)}

					<Button
						text={"Siguiente"}
						type="outlined"
						style={{ width: '100%', marginTop: 20 }}
						onPress={this.startOrder.bind(this)} />
				</View>
			</ScrollView>
		);
	}

	private itemImage(r) {
		return (
			<TouchableOpacity onPress={() => {
				this.productDialog.show(r);
			}}>
				<Image
					style={{ width: 60, height: 60, resizeMode: 'contain' }}
					source={{ uri: `https://pedidos.elcor.com.ar/images/${r.id_producto}.png` }}
					defaultSource={require('./images/logo.png')} />

				<Icon
					style={{ position: 'absolute', bottom: 5, right: 5 }}
					name="info"
					size={16}
					color="black" />
			</TouchableOpacity>
		);
	}

	private renderItems() {
		let { pricelist, products, orderQuantity, orderTotal } = this.state;

		/*
		"COD_BARRA": "7798060852235",
    "FECHA_MODI": "2019-05-04T11:09:27.290Z",
    "PRECIO": 2200,
    "Producto": "SUERO DE MANTECA EN POLVO ",
    "id": 5973,
    "id_lista": 28,
    "id_producto": "2011100003",
    "peso": 25,
    "precio_unidad": 2200,
    "precioxkg": 88,
    "stock": 0,
		*/

		return (
			<>
				<View
					style={{ width: '100%', display: 'flex', padding: 15, borderBottomColor: '#EAEAEA', borderBottomWidth: 1, flexDirection: "row" }}>
					<View style={{ flexGrow: 1 }}>
						<Title>Pedido Actual</Title>
						<Text>{orderQuantity} productos. Total $ {orderTotal.toFixed(2)}</Text>
					</View>
					<Button
						icon={<Icon name="done" />}
						text={""}
						textStyle={{ marginLeft: -12 }}
						type="outlined"
						style={{}}
						onPress={this.showDetails.bind(this)} />
				</View>

				<FlatList
					style={{ width: '100%' }}
					keyboardDismissMode="on-drag"
					keyboardShouldPersistTaps={'always'}
					showsVerticalScrollIndicator={false}
					data={products}
					keyExtractor={(item: any) => item.id_producto}
					renderItem={this.renderItem.bind(this)}
				/>

				<View style={{ backgroundColor: 'transparent', width: '100%', position: 'absolute', bottom: 0, padding: 10 }}>
					<Fab style={{ alignSelf: 'flex-end' }} mini icon={'add'} animated onPress={() => {
						this.searchDialog.show({
							data: pricelist,
							template: (r) => {
								return {
									id: r.id_producto,
									hasFocus: false,
									value: (
										<View style={{ flexDirection: 'column', display: 'flex' }}>
											<Title style={{ fontWeight: 'bold', color: r.Producto.toLowerCase().indexOf('tonadita') === -1 ? 'red' : 'black' }}>{r.Producto}</Title>
											<Text>Unidad: $ {parseFloat(r.PRECIO).toFixed(2)} / Kg: $ {parseFloat(r.PRECIOXKG).toFixed(2)}</Text>
											<Text>Stock: {r.stock || 0}</Text>
										</View>
									),
									left: this.itemImage(r),
									right: (
										<TouchableOpacity style={{ padding: 12 }} onPress={() => {
											this.addItem(r);
										}}>
											<Icon name="add" size={28} color="black" />
										</TouchableOpacity>
									)
								};
							},
							search: {
								keys: ['Producto']
							},
							cancelText: 'Cerrar'
						})
					}} />
				</View>
			</>
		);
	}

	private renderFinishOrder() {
		// Detalles
		// Campo OC
		// Total
		let { selectedAddress, client, products, orderQuantity, orderTotal, ocText } = this.state;

		let address = client.addresses.find(r => r.ID_DIRECCION_ENTREGA == selectedAddress),
			items = products.filter(r => r.quantity > 0);

		return (
			<>
				<ScrollView style={{ width: '100%' }}>
					<View
						style={{ width: '100%', padding: 15 }}>
						<View>
							<Title>Pedido Actual</Title>
							<Text>{orderQuantity} productos. Total $ {orderTotal.toFixed(2)}</Text>
						</View>

						<View style={{ marginTop: 20 }}>
							<Title>Cliente</Title>
							<Text>{client.NOMBRE}</Text>
							<Text>Dirección: {address.COD_DIRECCION_ENTREGA}</Text>
						</View>

						<View style={{ marginTop: 20, marginBottom: 0 }}>
							<Title>Detalle Pedido</Title>
							{items.map((item) => {
								return (
									<View style={{ width: '100%', flexDirection: 'row', paddingTop: 5 }}>
										<Text style={{ flexGrow: 1 }}>{item.Producto}</Text>
										<Text>{item.quantity}</Text>
									</View>
								)
							})}
						</View>

						<View style={{ marginTop: 20, marginBottom: 20 }}>
							<Title>Orden de Compra u Otros</Title>
							<TextInput
								value={ocText}
								autoCorrect={false}
								disableFullscreenUI
								onChangeText={(val) => {
									this.setState({
										ocText: val
									});
								}}
							/>
						</View>

						<Button
							text={"Crear Pedido"}
							type="outlined"
							style={{ width: '100%', marginTop: 20 }}
							onPress={this.createOrder.bind(this)} />

						<Button
							text={"Volver"}
							textColor="black"
							style={{ width: '100%', marginTop: 20 }}
							onPress={() => this.setState({
								step: OrderSteps.ITEMS
							})} />
					</View>
				</ScrollView>
			</>
		);
	}

	private renderLocked() {
		let { lockedText } = this.state;

		return (
			<>
				<ScrollView style={{ width: '100%' }}>
					<View
						style={{ width: '100%', padding: 15 }}>
						<View>
							<Title>Carga Bloqueada</Title>
							<Text>{lockedText}</Text>
						</View>
					</View>
				</ScrollView>
			</>
		);
	}

	render() {
		let { step, subtitle } = this.state;

		return (
			<Screen appBar={{
				hasBack: true,
				onBackPress: this.goBack.bind(this),
				title: "Nuevo Pedido",
				subtitle
			}}>

				<View style={styles.container}>
					{(step === OrderSteps.SELECT) ? this.renderSelect() : null}
					{(step === OrderSteps.ITEMS) ? this.renderItems() : null}
					{(step === OrderSteps.CREATE_ORDER) ? this.renderFinishOrder() : null}
					{(step === OrderSteps.LOCKED) ? this.renderLocked() : null}
				</View>

				<Loading ref={(ref) => this.loading = ref} />
				<Notifications ref={ref => this.notifications = ref} />
				<ProductDialog ref={(ref) => this.productDialog = ref} />
				<SearchDialog ref={(ref) => this.searchDialog = ref} />
			</Screen>
		);
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: '#fff',
		alignItems: 'center',
		// justifyContent: 'center',
		padding: 0,
	},
});
