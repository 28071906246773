import * as React from 'react';

import { Text } from 'react-native';

export interface ITitleProps {
	style?: any;
	children: any;
}

export default function Title(props: ITitleProps) {
	return (
		<Text
			style={{ 
				fontSize: 16, 
				fontWeight: 'bold', 
				marginBottom: 2, 
				marginTop: 2,
				...(props.style || {})
			}}
			numberOfLines={2}>
			{props.children}
		</Text>
	);
}
