import "abort-controller/polyfill";

import * as FileSystem from 'expo-file-system';
import * as Permissions from 'expo-permissions';

import User from './User';

class Requests {
	serviceUrl = `https://pedidos.elcor.com.ar`;

	constructor() { }

	async headers(headers = {}) {
		let userHeaders = await User.getHeaders();

		return {
			...userHeaders,
			...headers,
			'content-type': 'application/json'
		};
	}

	getUrl(url) {
		if (url.indexOf('https:') !== -1) {
			return url;
		}

		return `${this.serviceUrl}${url}`;
	}

	async request(url, headers: any = {}, options: any = {}) {
		headers = await this.headers(headers);

		try {
			if (options && options.timeout) {
				const controller = new AbortController(),
					signal = controller.signal,
					timeout = options.timeout;

				setTimeout(() => {
					controller.abort();
				}, timeout);

				options.signal = signal;
			}

			let response = await fetch(
				this.getUrl(url),
				{
					...options,
					headers
				}
			);

			let res = await response.json();

			if (response.status === 200) {
				return res;
			} else {
				throw new Error(res.error);
			}
		} catch (error) {
			throw error;
		}
	}

	async get(url, headers = {}) {
		return this.request(url, headers, {
			method: 'GET'
		});
	}

	async post(url, body = {}, headers = {}, options: any = {}) {
		return this.request(url, headers, {
			method: 'POST',
			body: JSON.stringify(body),
			...options
		});
	}

	async delete(url, headers = {}, options: any = {}) {
		return this.request(url, headers, {
			method: 'DELETE',
			...options
		});
	}

	public async webDownload(url, headers: any = {}, options: any = {}) {
		headers = await this.headers(headers);

		if (options && options.timeout) {
			const controller = new AbortController(),
				signal = controller.signal,
				timeout = options.timeout;

			setTimeout(() => {
				controller.abort();
			}, timeout);

			options.signal = signal;
		}

		let response = await fetch(url, {
			headers: {
				'accept': '*/*'
			},
			credentials: 'include',
			...options
		});

		return (await response.blob());
	}

	async download(url, path, filename, headers = {}, options = {}) {
		// Platform not iOS or Android
		// Open a browser tab!
		const { status } = await Permissions.askAsync(
			Permissions.CAMERA_ROLL,
		);

		if (status !== 'granted') {
			alert('Hey! You have not enabled selected permissions');

			throw new Error('No posee permisos para descargar el archivo');
		}

		// headers = await this.headers(headers);

		let dirPath = `${FileSystem.documentDirectory}${path}`,
			filePath = `${dirPath}/${filename}`;

		console.info(filePath);

		/*try {
			await FileSystem.makeDirectoryAsync(dirPath);
		} catch(e) {
			console.info(e);
		}*/

		url = this.getUrl(url);

		/*let downloadObject = FileSystem.createDownloadResumable(
			url,
			filePath
		);*/

		let res = await FileSystem.downloadAsync(url, filePath);//downloadObject.downloadAsync();

		console.info('URI: ', res);

		let uri = await FileSystem.getContentUriAsync(res.uri);

		return { uri };

		/*return await FileSystem.downloadAsync(
			this.getUrl(url),
			filePath,
			{
				// headers,
				...options
			}
		);*/
	}
}

export default new Requests();