import React, { Component } from 'react';
import { View, Platform } from 'react-native';

import Modal from "../Modal";

import { Text, ActivityIndicator, Colors } from 'react-native-paper';

export default class Loading extends Component<any, any> {
	state = {
		isVisible: false,
		text: 'Cargando...'
	};

	public show(text: string = 'Cargando...') {
		this.setState({
			isVisible: true,
			text
		});

		// Safe hide
		setTimeout(this.hide.bind(this), 10000);
	}

	public hide() {
		this.setState({
			isVisible: false
		});
	}

	private getStyles(): any {
		if (Platform.OS === 'web') {
			return {
				borderRadius: 10,
				backgroundColor: 'white',
				width: '100%',
				padding: 40,
				paddingTop: 30,
				paddingBottom: 30,
				flexDirection: 'row'
			};
		} else {
			return {
				position: 'absolute',
				bottom: -25,
				borderTopLeftRadius: 10,
				borderTopRightRadius: 10,
				backgroundColor: 'white',
				width: '100%',
				padding: 40,
				paddingTop: 30,
				paddingBottom: 30,
				flexDirection: 'row'
			}
		}
	}

	public render() {
		let { isVisible, text } = this.state;

		return (
			<Modal
				isVisible={isVisible}
				onBackButtonPress={() => this.setState({ isLoadingVisible: false })}
				onBackdropPress={() => this.setState({ isLoadingVisible: false })}
				style={{ marginLeft: 10, marginRight: 10, alignItems: 'flex-end' }}
			>
				<View style={this.getStyles()}>
					<ActivityIndicator
						animating
						color={Colors.blue400} />

					<Text style={{ marginLeft: 25 }}>{text}</Text>
				</View>
			</Modal>
		);
	}
}
