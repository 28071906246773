import * as React from 'react';

import { View } from 'react-native';
import { Icon, Heading } from 'material-bread';

export interface IEmptyBoxProps {
	icon: string,
	text: string,
	style?: any
}

export default function EmptyBox(props: IEmptyBoxProps) {
	return (
		<View style={{ flexDirection: 'column', width: '100%', alignItems: 'center', padding: 12, ...(props.style ?? {}) }}>
			<Icon name={props.icon} size={54} />
			<Heading type={5} text={props.text ?? ''} style={{ paddingTop: 12 }} />
		</View>
	);
}
